import { Component, OnInit, HostListener, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Auth0Service } from 'src/app/services/auth0/auth0.service';
import { SamuraiService } from 'src/services/samurai/samurai.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { Store } from '@ngrx/store';
import { AppState, Auth0Profile } from 'src/app/appState/app.state';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { appState } from 'src/app/appState/app.selectors';
import * as PROJECTS_ACTIONS from 'src/app/projects/store/projects.actions';

import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';

import { faCheckCircle, faExclamationCircle, faFileInvoice, faReceipt } from '@fortawesome/free-solid-svg-icons';

import { DateTime } from 'luxon';
import { TQDateTimeService } from '../services/tqdatetime.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit
{
  appState: AppState;
  appStateSubs: any;

  faCheckCircle = faCheckCircle;
  faExclamationCircle = faExclamationCircle;
  faFileInvoice = faFileInvoice;
  faReceipt = faReceipt;

  @ViewChild('subscriptionsExpansionPanel') 
  subscriptionsExpansionPanel: MatExpansionPanel;

  TQsession: any;
  TQsubscriptions: any[];

  prefLoginStartPad = "workpad"
  prefLogoutAfterInactiveFor = "60"

  prefLocTimeZone = "UTC";
  prefLocDateFormat = "YYYY-MM-DD";
  prefLocTimeFormat = "24h";
  prefLocWeekStart = "Sunday";
  prefLocWeekendDays = "Sa Su";

  prefWorkpadNumCols = 5;
  prefWorkpadCol1 = "planning";
  prefWorkpadCol2 = "todo";
  prefWorkpadCol3 = "doing";
  prefWorkpadCol4 = "waiting";
  prefWorkpadCol5 = "stopped";
  prefWorkpadCol1Mobile = "planning";
  prefWorkpadCol2Mobile = "todo";
  prefWorkpadCol3Mobile = "doing";
  prefWorkpadCol4Mobile = "waiting";
  prefWorkpadCol5Mobile = "stopped";

  prefTaskRadarEnabled = false;
  prefTaskRadarFreq = "monthly";
  prefTaskRadarRange = "60d";

  cfgRoleSelectedId = null;

  isValidSubscription: boolean = false;
  isCanceledSubscription: boolean = false;
  isCancelableSubscription: boolean = false;
  isUpgradableSubscription: boolean = true;
  isUnpaidSubscription: boolean = true;
  trialPlanAvailable: boolean = false;
  freePlanAvailable: boolean = false;
  paidPlanAvailable: boolean = false;

  clickedSubscriptionCancel = false;
  clickedProfileDelete = false;

  sentVerificationEmail = false;

  selectedTab = 0;

  constructor(
    private activeRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private store: Store,
    private auth0: Auth0Service,
    public  samApp: SamuraiService,
    private stripe: StripeService,
    private tqApi: TQApiService,
    private tqDT: TQDateTimeService,
    public  tqSession: TQSessionService,
  ) {}

  async ngOnInit()
  {
    const tab = this.activeRoute.snapshot.queryParams['tab']
    const result = this.activeRoute.snapshot.queryParams['result']
    const session_id = this.activeRoute.snapshot.queryParams['result']
    
    this.changeDetectorRef.detach()

    this.loadTQsession()

    if (this.appState?.status == 'loaded')
    {
      await this.tqSession.getPromotionsByProfile();
      await this.tqSession.getSubscriptionsByProfile();
      this.loadSubscriptions();
    }

    this.changeDetectorRef.reattach()

    this.appStateSubs = this.store.select(appState)
      .subscribe(async state => { 
        this.appState = state
        if (state.status != 'loaded') return;

        this.loadSubscriptions();

        switch (tab)
        {
          case 'subscriptions':
            this.selectedTab = 1;
            break;
          case 'configuration':
            this.selectedTab = 4;
            break;
          default:
            // Default to preferences tab after first subscription
            this.selectedTab = 2;
            break;
        }

        // Check whether the subscription is valid
        if (this.isValidSubscription == false)
        {
          this.selectedTab = 1;
        }

        // Check whether the email has been verified
        if (this.tqSession.hasEmailVerified() == false)
        {
          this.selectedTab = 0;
        }        
      })

    // Check for callback result from Stripe as failed 
    if (result == "KO") 
    {
      this.cancelSubscription();
    }

  }
   
  ngOnDestroy()
  {
    this.appStateSubs.unsubscribe(); 
  }

  @HostListener('document:keydown.control.s', ['$event'])
  CtrlS(event: any)
  {
    event.preventDefault();
    document.getElementById("PrefSaveButton").click()
  }


  /*--- PROFILE ---*/

  async updateProfile()
  {
    await this.tqApi.updateProfile(this.TQsession)

    this.samApp.saveStore("TQsession", this.TQsession)
  }

  async deleteProfile()
  {
    await this.tqApi.deleteProfile()

    // Unconditionally logout
    this.router.navigate(['logout'])
  }

  async savePreferences()
  {
    this.TQsession["prefLoginStartPad"]=this.prefLoginStartPad
    this.TQsession["prefLogoutAfterInactiveFor"]=this.prefLogoutAfterInactiveFor
    this.TQsession["prefLocTimeZone"]=this.prefLocTimeZone
    this.TQsession["prefLocWeekStart"]=this.prefLocWeekStart
    this.TQsession["prefLocWeekendDays"]=this.prefLocWeekendDays
    this.TQsession["prefLocDateFormat"]=this.prefLocDateFormat
    this.TQsession["prefLocTimeFormat"]=this.prefLocTimeFormat
    this.TQsession["prefWorkpadNumCols"]=this.prefWorkpadNumCols
    this.TQsession["prefWorkpadCol1"]=this.prefWorkpadCol1
    this.TQsession["prefWorkpadCol2"]=this.prefWorkpadCol2
    this.TQsession["prefWorkpadCol3"]=this.prefWorkpadCol3
    this.TQsession["prefWorkpadCol4"]=this.prefWorkpadCol4
    this.TQsession["prefWorkpadCol5"]=this.prefWorkpadCol5
    this.TQsession["prefWorkpadCol1Mobile"]=this.prefWorkpadCol1Mobile
    this.TQsession["prefWorkpadCol2Mobile"]=this.prefWorkpadCol2Mobile
    this.TQsession["prefWorkpadCol3Mobile"]=this.prefWorkpadCol3Mobile
    this.TQsession["prefWorkpadCol4Mobile"]=this.prefWorkpadCol4Mobile
    this.TQsession["prefWorkpadCol5Mobile"]=this.prefWorkpadCol5Mobile
    this.TQsession["prefTaskRadarEnabled"]=this.prefTaskRadarEnabled
    this.TQsession["prefTaskRadarFreq"]=this.prefTaskRadarFreq
    this.TQsession["prefTaskRadarRange"]=this.prefTaskRadarRange
    this.TQsession["cfgRoleSelectedId"]=this.cfgRoleSelectedId
   
    await this.updateProfile()
    
    this.store.dispatch(APP_ACTIONS.setTQroleSelectedId({id: this.cfgRoleSelectedId}))
    this.store.dispatch(APP_ACTIONS.setTQprofilePrefs({profile: this.TQsession}))   

    this.store.dispatch(APP_ACTIONS.projectFiltered({ id:0, code:'', color:'', list:[] }))
    this.store.dispatch(PROJECTS_ACTIONS.loadProjectsList())

    if (this.appState.lastPane == null)
    {
      this.router.navigate([this.prefLoginStartPad])
    }
    else
    {
      this.router.navigate([this.appState.lastPane])
    }
  }

  async checkEmailVerification()
  {
    // Login to check whether the email has been verified
    await this.auth0.signin("/loading")
  }

  async sendVerificationEmail()
  {
    this.sentVerificationEmail=true;

    await this.tqApi.sendVerificationEmail()
  }

  /*--- SESSION ---*/

  loadTQsession()
  {
    this.TQsession = this.samApp.retrieveStore("TQsession")

    this.prefLoginStartPad = this.TQsession["prefLoginStartPad"]
    this.prefLogoutAfterInactiveFor = this.TQsession["prefLogoutAfterInactiveFor"]
    this.prefLocTimeZone = this.TQsession["prefLocTimeZone"] || Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.prefLocWeekStart = this.TQsession["prefLocWeekStart"] || "Monday"
    this.prefLocWeekendDays = this.TQsession["prefLocWeekendDays"] || "Sa Su"
    this.prefLocDateFormat = this.TQsession["prefLocDateFormat"] || "YYYY-MM-DD"
    this.prefLocTimeFormat = this.TQsession["prefLocTimeFormat"] || "24h"
    this.prefWorkpadNumCols = this.TQsession["prefWorkpadNumCols"] || 5
    this.prefWorkpadCol1 = this.TQsession["prefWorkpadCol1"] || "planning"
    this.prefWorkpadCol2 = this.TQsession["prefWorkpadCol2"] || "todo"
    this.prefWorkpadCol3 = this.TQsession["prefWorkpadCol3"] || "doing"
    this.prefWorkpadCol4 = this.TQsession["prefWorkpadCol4"] || "waiting"
    this.prefWorkpadCol5 = this.TQsession["prefWorkpadCol5"] || "stopped"
    this.prefWorkpadCol1Mobile = this.TQsession["prefWorkpadCol1Mobile"] || "planning"
    this.prefWorkpadCol2Mobile = this.TQsession["prefWorkpadCol2Mobile"] || "todo"
    this.prefWorkpadCol3Mobile = this.TQsession["prefWorkpadCol3Mobile"] || "doing"
    this.prefWorkpadCol4Mobile = this.TQsession["prefWorkpadCol4Mobile"] || "waiting"
    this.prefWorkpadCol5Mobile = this.TQsession["prefWorkpadCol5Mobile"] || "stopped"
    this.prefTaskRadarEnabled = this.TQsession["prefTaskRadarEnabled"]
    this.prefTaskRadarFreq = this.TQsession["prefTaskRadarFreq"]
    this.prefTaskRadarRange = this.TQsession["prefTaskRadarRange"]

    this.cfgRoleSelectedId = this.TQsession["cfgRoleSelectedId"]
  }
  
  /*--- SUBSCRIPTIONS ---*/

  async addSubscription(subscription_plan?: string)
  {
    let id = this.appState.lastSubscription?.id;
    
    // Cancel the old subscription
    if (id == null || this.appState.lastSubscription?.dateCanceled != null)
    {
      this.tqApi.trace("NO current subscription to cancel before checkout")
    }
    else
    {
      this.tqApi.trace("Cancel current subscription before checkout")
      await this.tqApi.cancelSubscription(id, false)
    }
  
    // Create the new subscription
    const TQSubscription = {
      "subscription_id"        : this.appState.lastSubscription?.id || null,
      "subscription_plan"      : subscription_plan,
      "session_id"             : null, 
    }
    if (['trial', 'free'].some(plan => subscription_plan.includes(plan)))
    {
      await this.tqApi.postSubscription(TQSubscription)
    }
    else
    {
      // Add the Stripe session for non free plans
      const res = await this.tqApi.getStripeCheckoutSession(TQSubscription)
      TQSubscription["session_id"] = res['session_id']
      await this.tqApi.postSubscription(TQSubscription)

      // Proceed to Stripe checkout
      this.tqApi.trace("Entering Stripe checkout...")
      const checkout = await this.stripe.getStripeClient();
      const result = await checkout.redirectToCheckout({ sessionId: res['session_id'], })
        // .then(async (result) => { 
        //   if (result.error) 
        //   {
        //     // Cancel the new subscription
        //     this.cancelSubscription();
        //   }
        // })
    // NEVER REACHES HERE BECAUSE OF THE REDIRECT
    // this.tqApi.trace("Leaving Stripe checkout")
    }

    // Reload the subscriptions
    this.subscriptionsExpansionPanel.close();

    await this.tqSession.getSubscriptionsByProfile();
    this.loadSubscriptions()
  }

  async cancelSubscription()
  {
    let id = this.appState.lastSubscription?.id;
    if (id == null) return;

    // Cancel the subscription
    //    let res = await this.tqApi.deleteSubscription(id)
    await this.tqApi.cancelSubscription(id, false)

    // Reload the subscriptions
    await this.tqSession.getSubscriptionsByProfile();
    this.loadSubscriptions();

    this.clickedSubscriptionCancel = false;
  }

  async loadSubscriptions()
  {
    this.TQsubscriptions = this.tqSession.getTQsubscriptions();

    this.isValidSubscription = this.tqSession.isValidSubscription()
    this.isCanceledSubscription = this.tqSession.isCanceledSubscription()
    this.isCancelableSubscription = this.tqSession.isCancelableSubscription()
    this.isUpgradableSubscription = this.tqSession.isUpgradableSubscription()
    this.isUnpaidSubscription = this.tqSession.isUnpaidSubscription()

    // Check for paid plan availability
    this.paidPlanAvailable = this.samApp.getFeatures().STRIPE_allowBilling 

    // Check for trial plan availability
    this.trialPlanAvailable = this.samApp.getFeatures().allowTrialSubscriptions && this.allowTrialPromotion()
    if (this.trialPlanAvailable) this.isUnpaidSubscription = false;  

    // Check for free plan availability
    this.freePlanAvailable =  this.samApp.getFeatures().allowFreeSubscriptions || this.hasFreePromotion()
    if (this.freePlanAvailable) this.isUnpaidSubscription = false;  
  }

  allowTrialPromotion()
  {
    if (this.appState == undefined) return false; 
    // Get first trial subscription
    const trialSubscription = this.appState.trialSubscription
    
    // Check for no previous trial subscription
    if (trialSubscription?.id == null) 
    {
      return true;
    }

    // Check fot expired trial subscription
    if (this.tqDT.tqDateTime > DateTime.fromISO(trialSubscription.startDate).plus({days: 30}))
    {
      return false;
    }
      
    // Check for last subscription as trial and active
    const lastSubscription = this.appState.lastSubscription
    if (lastSubscription.plan.includes('trial') && lastSubscription.dateCanceled == null)
    {
      return false;
    }

    return true
  }

  hasFreePromotion()
  {
    let hasFreePromotion = false; 

    let promos = this.tqSession.getTQproductPromotions()
    if (promos != "")
    {
      hasFreePromotion = 
        promos.includes('TQVIP250101') || 
        promos.includes('LNKD240802') || 
        promos.includes('LNKD250101')
    }
    
    return hasFreePromotion
  }
 
  toggleClickProfileDelete()  
  {
    this.clickedProfileDelete = !this.clickedProfileDelete;
  }

  togglePrefTaskRadarEnabled()
  {
    this.prefTaskRadarEnabled = !this.prefTaskRadarEnabled;
  }
}
