import { Component, HostListener, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTabGroup } from '@angular/material/tabs';

import { TreeNode } from 'primeng/api';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';
import { ProjectsState } from 'src/app/projects/store/projects.state';
import { projectsState } from 'src/app/projects/store/projects.selectors';
import { TasksState } from 'src/app/tasks/store/tasks.state';
import { tasksState } from 'src/app/tasks/store/tasks.selectors';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import * as TASKS_ACTIONS from 'src/app/tasks/store/tasks.actions';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQDateTimeService } from 'src/app/services/tqdatetime.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { DateTime } from 'luxon'

import { faFile, faQuestionCircle, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class TaskComponent implements OnInit, OnDestroy
{
  faFile = faFile;
  faQuestionCircle = faQuestionCircle;
  faCalendarCheck = faCalendarCheck;

  appState: AppState;
  appStateSubs: any;

  projectsState: ProjectsState;
  projectsStateSubs: any;

  tasksState: TasksState;
  tasksStateSubs: any;
  canAddTask: boolean = true;
  canEditTask: boolean = true;

  selectedTab = 0;
  selectedTab2 = 0;
  clicked = false;

  // Local copy of tasksState.selectedTaskId
  taskId: number; 
  taskProject_id: number;
  taskTitle: string = "";
  taskDescription: string = "";
  taskStatus: string = "todo";
  taskPriority: string = "C";
  taskStartWorkDate: DateTime;
  taskStartWorkTime: string = "";
  taskStartWorkTZ: string = null;
  taskStartWorkTZDate: DateTime;
  taskStartWorkTZTime: string = "";
  taskEndWorkDate: DateTime;
  taskEndWorkTime: string = "";
  taskEndWorkTZ: string = null;
  taskEndWorkTZDate: DateTime;
  taskEndWorkTZTime: string = "";
  taskDueDate: DateTime;
  taskDueTime: string = "";
  taskDueTZ: string = null;
  taskDueTZDate: DateTime;
  taskDueTZTime: string = "";
  taskTargetDate: DateTime // = this.tqDT.tqDateTime;
  taskTargetTime: string = "";
  taskTargetTZ: string = null;
  taskTargetTZDate: DateTime;
  taskTargetTZTime: string = "";
  taskExeTime: string = "";
  taskExeTZ: string = null;
  taskExeTZTime: string = "";
  taskExeDuration: string = "";
  taskRepWeekdays = "";
  taskRepWeeks = "";
  taskRepMonths = [];
  taskRepMonthDay: string = "";
  taskRelDate: string = "future";
  taskOrigin: string = null;

  projectsNodes: TreeNode[];
  selectedProject: any;

  activities: any;
  activities_number: number = 0;
  activities_time: number = 0;
  
  activityReportStartDate: DateTime = null;
  activityReportEndDate: DateTime = null;

  // TODO Create a widget for this, like tq-weekday-widget
  moy : any[] = [
    { name: "Jan.", value: 1 },
    { name: "Feb.", value: 2 },
    { name: "Mar.", value: 3 },
    { name: "Apr.", value: 4 },
    { name: "May.", value: 5 },
    { name: "Jun.", value: 6 },
    { name: "Jul.", value: 7 },
    { name: "Aug.", value: 8 },
    { name: "Sep.", value: 9 },
    { name: "Oct.", value: 10 },
    { name: "Nov.", value: 11 },
    { name: "Dec.", value: 12 },
  ];
  
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private store: Store,
    public  samApp: SamuraiService,
    public  tqSession: TQSessionService,
    private tqApi: TQApiService,
    public  tqDT: TQDateTimeService,
  )
  {}

  async ngOnInit()
  {
    this.changeDetectorRef.detach()

    this.appStateSubs = this.store.select(appState)
    .subscribe( state => {
      this.appState = state       
      
      this.canAddTask = this.tqSession.canAddTask()     
    })

    this.projectsStateSubs = this.store.select(projectsState)
      .subscribe( state => {
        this.projectsState = state 
        
        this.canAddTask = this.tqSession.canAddTask()

        if (state.status == 'loaded')
        {
          this.projectsNodes = [...this.buildProjectSelector()]
          this.selectedProject=this.findProjectNode(this.projectsNodes, this.taskProject_id)
        }
     })

    this.tasksStateSubs = this.store.select(tasksState)
      .subscribe( state => {
        this.tasksState = state 

        this.canAddTask = this.tqSession.canAddTask()
    })

    // Check for a new task
    this.taskId = Number.parseInt(this.activeRoute.snapshot.paramMap.get('id'))
    if ( this.taskId == 0 )
    {
      this.taskProject_id = this.appState.projectFilterId;
      if (this.taskProject_id == 0)
      {
        this.taskProject_id = this.appState.default_project_id 
      }
      this.selectedProject=this.findProjectNode(this.projectsNodes, this.taskProject_id)
    }
    else
    {
      await this.getTask(this.taskId)
      await this.getTaskActivity()
    }

    // Check if the user has permission to edit the task of this project
    let project = this.projectsState.TQprojects.find(p => p.id == this.taskProject_id)
    if (project == null) 
    {
      this.canEditTask = false;
    }
    else
    {
      this.canEditTask = (project['accessRole'] != 'reader');
    }       
    
    // TODO Temporary fix for the taskProject_id, until imports are fully implemented
    this.canEditTask = true; 

    // Get query parameters
    this.activeRoute.queryParams.subscribe(params => {
      if (params['YYYY'] == null) return;

      let startWorkDate = params['YYYY']+"-"+params['MM'].padStart(2, '0')+"-"+params['DD'].padStart(2, '0')
      let startWorkTime = null;
      let endWorkTime = null;
      if (params['HH'] != null) 
      {
        startWorkTime = params['HH'].padStart(2, '0')+":00"
        endWorkTime = params['HH'].padStart(2, '0')+":30"
      }

      if (startWorkTime == "24:00") 
      {
        startWorkTime = null
        endWorkTime = null
      }

      switch (params ['from'])
      {
        case "todopad": 
          // this.taskStartWorkTZDate = this.tqDT.toLuxonFromISO((startWorkDate))
          // this.taskStartWorkTZTime = null
          // this.updateStartWorkDateTime()
          this.taskTargetTZDate = this.tqDT.toLuxonFromISO((startWorkDate))
          this.taskTargetTZTime = null
          this.updateTargetDateTime()
          break;
        case "weekpad": 
          // this.taskStartWorkTZDate = this.tqDT.toLuxonFromISO((startWorkDate))
          // this.taskStartWorkTZTime = startWorkTime
          // this.updateStartWorkDateTime()
          // this.taskEndWorkTZDate = this.tqDT.toLuxonFromISO((startWorkDate))
          // this.taskEndWorkTZTime = endWorkTime
          // this.updateEndWorkDateTime()
          // this.taskExeTZTime = startWorkTime
          // this.taskExeDuration = "0h30m";
          // this.updateExeTime()
          this.taskTargetTZDate = this.tqDT.toLuxonFromISO((startWorkDate))
          this.taskTargetTZTime = startWorkTime
          this.updateTargetDateTime()
          break;
        case "monthpad": 
          // this.taskStartWorkTZDate = this.tqDT.toLuxonFromISO((startWorkDate))
          // this.updateStartWorkDateTime()
          // this.taskEndWorkTZDate = this.tqDT.toLuxonFromISO((startWorkDate))
          // this.updateEndWorkDateTime()
          // this.taskExeTZTime = startWorkTime
          // this.taskExeDuration = "0h30m";
          // this.updateExeTime()
          this.taskTargetTZDate = this.tqDT.toLuxonFromISO((startWorkDate))
          this.taskTargetTZTime = startWorkTime
          this.updateTargetDateTime()
          break;
        // case "workpad": 
        // break;
      }
    });

    // Show description tab if not empty    
    this.selectedTab2=(this.taskDescription?.length>0)?0:1

    // Check for a tab query parameter
    let tab = this.activeRoute.snapshot.queryParams['tab']
    switch (tab)
    {
      case "description": this.selectedTab = 0; this.selectedTab2 = 0; break;
      case "schedule":    this.selectedTab = 0; this.selectedTab2 = 1; break;
      case "activity":    this.selectedTab = 1; break;
    } 
    
    // Focus on the first field at top, but not onMobile
    if (!this.samApp.onMobile) 
    {
      let elem = document.getElementById("TaskTitle");
      setTimeout(() =>
        {
          elem.focus();
          elem.scrollIntoView({behavior: 'instant', inline: 'center', block: 'center'});
        }, 100);
    }

    this.changeDetectorRef.reattach()
  }

  ngOnDestroy(): void 
  {
    this.appStateSubs.unsubscribe()
    this.projectsStateSubs.unsubscribe()
    this.tasksStateSubs.unsubscribe()
  }

  @HostListener('document:keydown.control.s', ['$event'])
  CtrlS(event: any)
  {
    event.preventDefault();
    document.getElementById("TaskSaveButton").click()
  }
  @HostListener('document:keydown.control.d', ['$event'])
  CtrlD(event: any)
  {
    event.preventDefault();
    document.getElementById("TaskDuplicateButton").click()
  }
  @HostListener('document:keydown.escape', ['$event'])
  Escape(event: any)
  {
    event.preventDefault();
    //document.getElementById("TaskCancelButton").click()
  }

  async getTask(id: number)
  {
    // Ignore new tasks
    if (id == 0) return;

    try
    {
      var res = await this.tqApi.getTask(id)
    }
    catch (apiError)
    {
      this.router.navigate(["/loading"])
    }

    if (res["project_id"] == null)
    {
      this.taskProject_id = 0
    }
    else
    {
      this.taskProject_id = res["project_id"];
      if (this.taskProject_id == null)
      {
        this.taskProject_id = 0
      }
    }
    this.selectedProject=this.findProjectNode(this.projectsNodes, this.taskProject_id)

    this.taskTitle = res["title"]
    this.taskDescription = res["description"]
    this.taskStatus = res["status"]
    if (this.taskStatus == "") { this.taskStatus = "todo" };
    this.taskPriority = res["priority"]
    if (this.taskPriority == null) { this.taskPriority = "C" };

    this.taskStartWorkTZ = res["startWorkTZ"] 
    if (this.taskStartWorkTZ == null)
    {
      this.taskStartWorkDate = this.tqDT.toLuxon(res["startWorkDate"]);
      this.taskStartWorkTime = res["startWorkTime"]; 
      this.taskStartWorkTZDate = this.taskStartWorkDate;
      this.taskStartWorkTZTime = this.taskStartWorkTime;
    }
    else if (this.taskStartWorkTZ == "N")
    {
      this.taskStartWorkDate = this.tqDT.toLuxon(res["startWorkDate"]);
      this.taskStartWorkTime = res["startWorkTime"]; 
      this.taskStartWorkTZDate = this.taskStartWorkDate;
      this.taskStartWorkTZTime = this.taskStartWorkTime;
    }
    else 
    {
      this.taskStartWorkDate = this.tqDT.toLuxon(res["startWorkDate"]);
      this.taskStartWorkTime = res["startWorkTime"]; 
      this.taskStartWorkTZDate = this.tqDT.toLuxon(res["startWorkTZDate"]);
      this.taskStartWorkTZTime = res["startWorkTZTime"];
    }

    this.taskEndWorkTZ = res["endWorkTZ"] 
    if (this.taskEndWorkTZ == null)
    {
      this.taskEndWorkDate = this.tqDT.toLuxon(res["endWorkDate"]);
      this.taskEndWorkTime = res["endWorkTime"]; 
      this.taskEndWorkTZDate = this.taskEndWorkDate;
      this.taskEndWorkTZTime = this.taskEndWorkTime;
    }
    else if (this.taskEndWorkTZ == "N")
    {
      this.taskEndWorkDate = this.tqDT.toLuxon(res["endWorkDate"]);
      this.taskEndWorkTime = res["endWorkTime"]; 
      this.taskEndWorkTZDate = this.taskEndWorkDate;
      this.taskEndWorkTZTime = this.taskEndWorkTime;
    }
    else 
    {
      this.taskEndWorkDate = this.tqDT.toLuxon(res["endWorkDate"]);
      this.taskEndWorkTime = res["endWorkTime"]; 
      this.taskEndWorkTZDate = this.tqDT.toLuxon(res["endWorkTZDate"]);
      this.taskEndWorkTZTime = res["endWorkTZTime"];
    }

    this.taskDueTZ = res["dueTZ"] 
    if (this.taskDueTZ == null)
    {
      this.taskDueDate = this.tqDT.toLuxon(res["dueDate"]);
      this.taskDueTime = res["dueTime"]; 
      this.taskDueTZDate = this.taskDueDate;
      this.taskDueTZTime = this.taskDueTime;
    }
    else if (this.taskDueTZ == "N")
    {
      this.taskDueDate = this.tqDT.toLuxon(res["dueDate"]);
      this.taskDueTime = res["dueTime"]; 
      this.taskDueTZDate = this.taskDueDate;
      this.taskDueTZTime = this.taskDueTime;
    }
    else 
    {
      this.taskDueDate = this.tqDT.toLuxon(res["dueDate"]);
      this.taskDueTime = res["dueTime"]; 
      this.taskDueTZDate = this.tqDT.toLuxon(res["dueTZDate"]);
      this.taskDueTZTime = res["dueTZTime"];
    }

    this.taskTargetTZ = res["targetTZ"] 
    if (this.taskTargetTZ == null)
    {
      this.taskTargetDate = this.tqDT.toLuxon(res["targetDate"]);
      this.taskTargetTime = res["targetTime"]; 
      this.taskTargetTZDate = this.taskTargetDate;
      this.taskTargetTZTime = this.taskTargetTime;
    }
    else if (this.taskTargetTZ == "N")
    {
      this.taskTargetDate = this.tqDT.toLuxon(res["targetDate"]);
      this.taskTargetTime = res["targetTime"]; 
      this.taskTargetTZDate = this.taskTargetDate;
      this.taskTargetTZTime = this.taskTargetTime;
    }
    else 
    {
      this.taskTargetDate = this.tqDT.toLuxon(res["targetDate"]);
      this.taskTargetTime = res["targetTime"]; 
      this.taskTargetTZDate = this.tqDT.toLuxon(res["targetTZDate"]);
      this.taskTargetTZTime = res["targetTZTime"];
    }

    this.taskExeTZ = res["exeTZ"];
    if (this.taskExeTZ == null)
      {
        this.taskExeTime = res["exeTime"]; 
        this.taskExeTZTime = this.taskExeTime
      }
      else if (this.taskExeTZ == "N")
      {
        this.taskExeTime = res["exeTime"]; 
        this.taskExeTZTime = this.taskExeTime
      }
      else 
      {
        this.taskExeTime = res["exeTime"]; 
        this.taskExeTZTime = res["exeTZTime"]; 
      }
    this.taskExeDuration = res["exeDuration"];

    // Temporary replace CR with <br> for old descriptions saved from textarea controls
    if (res['description'] != null) this.taskDescription = res["description"].replace(/(?:\r\n|\r|\n)/g, '<br>');

    this.taskRepWeekdays = res["repWeekdays"];
    this.taskRepWeeks = res["repWeeks"];

    if (res.repJan) this.taskRepMonths.push(1);
    if (res.repFeb) this.taskRepMonths.push(2);
    if (res.repMar) this.taskRepMonths.push(3);
    if (res.repApr) this.taskRepMonths.push(4);
    if (res.repMay) this.taskRepMonths.push(5);
    if (res.repJun) this.taskRepMonths.push(6);
    if (res.repJul) this.taskRepMonths.push(7);
    if (res.repAug) this.taskRepMonths.push(8);
    if (res.repSep) this.taskRepMonths.push(9);
    if (res.repOct) this.taskRepMonths.push(10);
    if (res.repNov) this.taskRepMonths.push(11);
    if (res.repDec) this.taskRepMonths.push(12);

    this.taskRepMonthDay = res['repDay'];

    this.taskRelDate = res["relDate"]
    if (this.taskRelDate == null) { this.taskRelDate = "future" };

    this.taskOrigin = res["origin"];
  }

async addTask()
  {
    // Ignore empty text tasks
    if (this.taskTitle == "")
    {
      this.clicked = false;
      return;
    }

    let TQTask = {
        "id"         : this.taskId,
        "profile_id" : this.appState.TQprofileId,
        "project_id" : this.selectedProject.data,
        "title"      : this.taskTitle,
        "description" : this.taskDescription || '',
        "status" : this.taskStatus,
        "priority" : this.taskPriority,
        "startWorkDate" : this.tqDT.formatDateISO(this.taskStartWorkDate),
        "startWorkTime" : this.taskStartWorkTime || '',
        "startWorkTZ" : this.taskStartWorkTZ || '',
        "startWorkTZDate" : this.tqDT.formatDateISO(this.taskStartWorkTZDate),
        "startWorkTZTime" : this.taskStartWorkTZTime || '',
        "endWorkDate" : this.tqDT.formatDateISO(this.taskEndWorkDate),
        "endWorkTime" : this.taskEndWorkTime || '',
        "endWorkTZ" : this.taskEndWorkTZ || '',
        "endWorkTZDate" : this.tqDT.formatDateISO(this.taskEndWorkTZDate),
        "endWorkTZTime" : this.taskEndWorkTZTime || '',
        "dueDate" : this.tqDT.formatDateISO(this.taskDueDate),
        "dueTime" : this.taskDueTime || '',
        "dueTZ" : this.taskDueTZ || '',
        "dueTZDate" : this.tqDT.formatDateISO(this.taskDueTZDate),
        "dueTZTime" : this.taskDueTZTime || '',
        "targetDate" : this.tqDT.formatDateISO(this.taskTargetDate),
        "targetTime" : this.taskTargetTime || '',
        "targetTZ" : this.taskTargetTZ || '',
        "targetTZDate" : this.tqDT.formatDateISO(this.taskTargetTZDate),
        "targetTZTime" : this.taskTargetTZTime || '',
        "exeTime" : this.taskExeTime || '',
        "exeTZ" : this.taskExeTZ || '',
        "exeTZTime" : this.taskExeTZTime || '',
        "exeDuration" : this.taskExeDuration || '',
        "repWeekdays" : this.taskRepWeekdays ?? '',  // TODO replicate to all || ''
        "repWeeks" : this.taskRepWeeks ?? '',        
        // "repMonth" : this.taskRepMonths ?? '',  // TODO replicate to all || ''
        "repJan" : this.taskRepMonths.includes(1),
        "repFeb" : this.taskRepMonths.includes(2),
        "repMar" : this.taskRepMonths.includes(3),
        "repApr" : this.taskRepMonths.includes(4),
        "repMay" : this.taskRepMonths.includes(5),
        "repJun" : this.taskRepMonths.includes(6),
        "repJul" : this.taskRepMonths.includes(7),
        "repAug" : this.taskRepMonths.includes(8),
        "repSep" : this.taskRepMonths.includes(9),
        "repOct" : this.taskRepMonths.includes(10),
        "repNov" : this.taskRepMonths.includes(11),
        "repDec" : this.taskRepMonths.includes(12),
        "repDay" : this.taskRepMonthDay || '',
        "relDate" : this.taskRelDate,
        //"origin" : this.taskOrigin || "",
      }

    let res = await this.tqApi.postTask(TQTask)

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: res['id']}))

    this.router.navigate([this.appState.lastPane])
  }

  async duplicateTask()
  {

    let res = await this.tqApi.duplicateTask(this.taskId)

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: res['id']}))

    this.router.navigate([this.appState.lastPane])
  }

  cancelTask()
  {
    this.router.navigate([this.appState.lastPane])
  }

  async deleteTask()
  {
    await this.tqApi.deleteTask(this.taskId)

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: 0}))

    this.router.navigate([this.appState.lastPane])
  }

  /**
   * Update date and time based on the TZ
   */

  updateStartWorkDateTime()
  {
    if (this.taskStartWorkTZ == null)
    {
      this.taskStartWorkDate = this.taskStartWorkTZDate;
      this.taskStartWorkTime = this.taskStartWorkTZTime;
    }
    else if (this.taskStartWorkTZ == "N")  
    {
      this.taskStartWorkDate = this.taskStartWorkTZDate;
      this.taskStartWorkTime = this.taskStartWorkTZTime;
    }
    else
    {
      let datePrf = this.tqDT.formatDate(this.taskStartWorkTZDate)
      let timeISO = this.tqDT.timeTo24h(this.taskStartWorkTZTime) // Not needed  

      let date = this.tqDT.dateTZToTZ(this.taskStartWorkTZ, datePrf, timeISO)
      let time = this.tqDT.timeTZToTZ(this.taskStartWorkTZ, datePrf, timeISO)

      this.taskStartWorkDate = this.tqDT.toLuxon(date);
      this.taskStartWorkTime = time
    } 
  }

  updateEndWorkDateTime()
  {
    if (this.taskEndWorkTZ == null)
    {
      this.taskEndWorkDate = this.taskEndWorkTZDate;
      this.taskEndWorkTime = this.taskEndWorkTZTime;
    }
    else if (this.taskEndWorkTZ == "N")  
    {
      this.taskEndWorkDate = this.taskEndWorkTZDate;
      this.taskEndWorkTime = this.taskEndWorkTZTime;
    }
    else
    {
      let datePrf = this.tqDT.formatDate(this.taskEndWorkTZDate)
      let timeISO = this.tqDT.timeTo24h(this.taskEndWorkTZTime) // Not needed  

      let date = this.tqDT.dateTZToTZ(this.taskEndWorkTZ, datePrf, timeISO)
      let time = this.tqDT.timeTZToTZ(this.taskEndWorkTZ, datePrf, timeISO)

      this.taskEndWorkDate = this.tqDT.toLuxon(date);
      this.taskEndWorkTime = time
    } 
  }

  updateDueDateTime()
  {
    if (this.taskDueTZ == null)
    {
      this.taskDueDate = this.taskDueTZDate;
      this.taskDueTime = this.taskDueTZTime;
    }
    else if (this.taskDueTZ == "N")  
    {
      this.taskDueDate = this.taskDueTZDate;
      this.taskDueTime = this.taskDueTZTime;
    }
    else
    {
      let datePrf = this.tqDT.formatDate(this.taskDueTZDate)
      let timeISO = this.tqDT.timeTo24h(this.taskDueTZTime) // Not needed  

      let date = this.tqDT.dateTZToTZ(this.taskDueTZ, datePrf, timeISO)
      let time = this.tqDT.timeTZToTZ(this.taskDueTZ, datePrf, timeISO)

      this.taskDueDate = this.tqDT.toLuxon(date);
      this.taskDueTime = time
    } 
  }

  updateTargetDateTime()
  {
    if (this.taskTargetTZ == null)
    {
      this.taskTargetDate = this.taskTargetTZDate;
      this.taskTargetTime = this.taskTargetTZTime;
    }
    else if (this.taskTargetTZ == "N")  
    {
      this.taskTargetDate = this.taskTargetTZDate;
      this.taskTargetTime = this.taskTargetTZTime;
    }
    else
    {
      let datePrf = this.tqDT.formatDate(this.taskTargetTZDate)
      let timeISO = this.tqDT.timeTo24h(this.taskTargetTZTime) // Not needed  

      let date = this.tqDT.dateTZToTZ(this.taskTargetTZ, datePrf, timeISO)
      let time = this.tqDT.timeTZToTZ(this.taskTargetTZ, datePrf, timeISO)

      this.taskTargetDate = this.tqDT.toLuxon(date);
      this.taskTargetTime = time
    } 
  }

  updateExeTime()
  {
    if (this.taskExeTZ == null)
    {
      this.taskExeTime = this.taskExeTZTime;
    }
    else if (this.taskExeTZ == "N")  
    {
      this.taskExeTime = this.taskExeTZTime;
    }
    else
    {
      let timeISO = this.tqDT.timeTo24h(this.taskExeTZTime) // Not needed  

      let time = this.tqDT.timeTZToTZ(this.taskExeTZ, null, timeISO)

      this.taskTargetTime = time
    } 
  }  

  /**
   * Clear and copy functions
   */

  clearStartWorkDate()
  {
    this.taskStartWorkDate = null;
    this.taskStartWorkTZDate = null;
    this.clearStartWorkTime()
  }
  clearStartWorkTime()
  {
    this.taskStartWorkTime = null;
    this.taskStartWorkTZ = null;
    this.taskStartWorkTZTime = null;
  }

  clearEndWorkDate()
  {
    this.taskEndWorkDate = null;
    this.taskEndWorkTZDate = null;
    this.clearEndWorkTime()
  }
  clearEndWorkTime()
  {
    this.taskEndWorkTime = null;
    this.taskEndWorkTZ = null;
    this.taskEndWorkTZTime = null;
  }

  clearDueDate()
  {
    this.taskDueDate = null;
    this.taskDueTZDate = null;
    this.clearDueTime()
  }
  clearDueTime()
  {
    this.taskDueTime = null;
    this.taskDueTZ = null;
    this.taskDueTZTime = null;
  }

  clearTargetDate()
  {
    this.taskTargetDate = null;
    this.taskTargetTZDate = null;
    this.clearTargetTime()
  }
  clearTargetTime()
  {
    this.taskTargetTime = null;
    this.taskTargetTZ = null;
    this.taskTargetTZTime = null;
  }

  clearExeTime()
  {
    this.taskExeTime = null;
    this.taskExeTZ = null;
    this.taskExeTZTime = null;
    this.clearExeDuration();
  }

  clearExeDuration()
  {
    this.taskExeDuration = null;
  }

  copyEndToStart()
  {
    this.taskStartWorkDate = this.taskEndWorkDate;
    this.taskStartWorkTime = this.taskEndWorkTime;
  }

  copyDueToStart()
  {
    this.taskStartWorkDate = this.taskDueDate;
    this.taskStartWorkTime = this.taskDueTime;
  }

  copyStartToEnd()
  {
    this.taskEndWorkDate = this.taskStartWorkDate;    
    this.taskEndWorkTime = this.taskStartWorkTime;
  }

  copyDueToEnd()
  {
    this.taskEndWorkDate = this.taskDueDate;
    this.taskEndWorkTime = this.taskDueTime;
  }

  copyStartToDue()
  {
    this.taskDueDate = this.taskStartWorkDate;
    this.taskDueTime = this.taskStartWorkTime;
  }

  copyEndToDue()
  {
    this.taskDueDate = this.taskEndWorkDate;
    this.taskDueTime = this.taskEndWorkTime;
  }

  copyStartToTarget()
  {
    this.taskTargetDate = this.taskStartWorkDate;
    this.taskTargetTime = this.taskStartWorkTime;
  }

  copyEndToTarget()
  {
    this.taskTargetDate = this.taskEndWorkDate;
    this.taskTargetTime = this.taskEndWorkTime;
  }
  
  copyDueToTarget()
  {
    this.taskTargetDate = this.taskDueDate;
    this.taskTargetTime = this.taskDueTime;
  }

  copyStartTimeToExeTime()
  {
    this.taskExeTime = this.taskStartWorkTime;
  }

  copyEndTimeToExeTime()
  {
    this.taskExeTime = this.taskEndWorkTime;
  }

  copyDueTimeToExeTime()
  {
    this.taskExeTime = this.taskDueTime;
  }

  clearRepWeekdays()
  {
    this.taskRepWeekdays = "";
  }

  clearRepWeeks()
  {
    this.taskRepWeeks = "";
  }

  toggleWorkDays()
  {
    const allDays = "MO TU WE TH FR SA SU";
    const weekendDays = this.appState.prefLocWeekendDays.split(' ');
    // Remove weekend days    
    this.taskRepWeekdays = allDays
      .split(' ')
      .filter(day => !weekendDays.includes(day))
      .join(' ');
  }

  toggleWeekend()
  {
    this.taskRepWeekdays = this.appState.prefLocWeekendDays;
  }

  clearRepMonths()
  {
    this.taskRepMonths = [];
  }

  clearRepMonthDays()
  {
    this.taskRepMonthDay = null;
  }

  getRepMonthDays()
  {
    var res: Array<string> = [];

    for (var d = 1; d<=31; d++)
    {
      res.push((d.toString()))
    }
    return res;
  }

  buildProjectSelector(): TreeNode[]
  {
    let tree:TreeNode[] = []

    function getNodes(projectList, levelProjects: Array<any>, level: number, ignoreProject: number)
    {
      const nodes = [];
      levelProjects.forEach( p => 
      {
        if (p.id == ignoreProject) return;

        let children = projectList.filter(ch => ch.parent_project_id == p.id)
        nodes.push(
          {
            data: p.id,
            label: p.code,
            children: (children.length != 0) 
                        ? getNodes(projectList, 
                                   children, 
                                   level+1, 
                                   ignoreProject) 
                        : [],
            //expandedIcon: "pi pi-folder",
            //collapsedIcon: "pi pi-folder",
            //selectable: p.id != ignoreProject,
          }
        )
      })
      return nodes
    }

    let projectList = [...this.projectsState.TQprojects] 
    // Start with root projects as level 0
    tree = getNodes(projectList, projectList.filter(p => p.parent_project_id == null), 0, 0)
    return tree
  }

  findProjectNode(tree:TreeNode[], id:number): TreeNode
  {
    for (let i in tree)
    {
      let p = tree[i]
      if (p.data == id) return p       
      if (p.children.length != 0 ) {
        let node = this.findProjectNode(p.children, id)
        if (node) return node
      }
    }
  }

  projectNodeSelect(event)
  {
    this.taskProject_id = event.node.data
  }

  projectNodeUnselect(event)  
  {
    // Reselect the project to avoid unselection
    setTimeout(() => {
        this.selectedProject=this.findProjectNode(this.projectsNodes, this.taskProject_id)
      });
  }

  confirmDelete() 
  {
    this.confirmationService.confirm({
      key: 'deleteTask',
      accept: async () => { 
        await this.deleteTask()
      },
      reject: (type:ConfirmEventType) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            this.clicked = false
          break;
          case ConfirmEventType.CANCEL:
            this.clicked = false
          break;
        }
      }
    });
  }

  async getTaskActivity()
  {
    this.activities_number = 0
    this.activities_time = 0
    this.activities = []
    if (this.samApp.getFeatures().allowActivityLog == false) return;

    try
    {
      let startDate = this.tqDT.formatDateISO(this.activityReportStartDate)
      let endDate = this.tqDT.formatDateISO(this.activityReportEndDate)
      var res: any = await this.tqApi.getActivitySummaryByTaskId(this.taskId, startDate, endDate)
    }
    catch (apiError)
    {
      this.router.navigate(["/loading"])
    }
    if (res == null) return;

    // Load activities
    for (let i=0; i<res.length; i++)
    {
      this.activities.push(
      {
        startDate:   res[i][0],
        numAct:      res[i][1],
        sumValue:    res[i][2],
        description: res[i][3],
      })
    }

    // Get activity summary
    this.activities_number = this.activities.reduce((acc, act) => acc + act.numAct, 0)
    this.activities_time = this.activities.reduce((acc, act) => acc + act.sumValue, 0)
  }


  editTaskActivity()
  {
    // Leaving component
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'activity' })) 

    this.store.dispatch(APP_ACTIONS.setBackURL({ url:'/task/'+this.taskId+'?tab=activity' })) 
    this.router.navigate(["/activity"], { queryParams: { task: this.taskId } });
  }


  clearActivityReportStartDate()
  {
    this.activityReportStartDate = null;
    this.getTaskActivity();
  }

  clearActivityReportEndDate()
  {
    this.activityReportEndDate = null;
    this.getTaskActivity();
  }

  updateActivityReportStartDate()
  {
    this.getTaskActivity();
  }

  updateActivityReportEndDate()
  {
    this.getTaskActivity();
  }


  async reportActivityPDF()
  {
    const PDF:Blob = await this.tqApi.getActivityPDFReportByTaskId(
      this.taskId, 
      this.tqDT.formatDateISO(this.activityReportStartDate), 
      this.tqDT.formatDateISO(this.activityReportEndDate),
      true,
      true
    );

    const URL = window.URL.createObjectURL(PDF);

    // Create a temporary <a> element
    const a = document.createElement('a');
    a.href = URL;
    a.target = '_blank';
    a.rel = 'noopener noreferrer'; 
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke after a short delay
    setTimeout(() => window.URL.revokeObjectURL(URL), 5*60*1000);

    this.clicked = false;
  }
  

  //--- Task debugging functions ---//

  dumpTask() 
  {
    console.log("---");
    console.log("taskStartWorkDate", this.taskStartWorkDate);
    console.log("taskStartWorkTime", this.taskStartWorkTime);
    console.log("taskStartWorkTZ", this.taskStartWorkTZ);
    console.log("taskStartWorkTZDate", this.taskStartWorkTZDate);
    console.log("taskStartWorkTZTime", this.taskStartWorkTZTime);
    console.log("taskEndWorkDate", this.taskEndWorkDate);
    console.log("taskEndWorkTime", this.taskEndWorkTime);
    console.log("taskEndWorkTZ", this.taskEndWorkTZ);
    console.log("taskEndWorkTZDate", this.taskEndWorkTZDate);
    console.log("taskEndWorkTZTime", this.taskEndWorkTZTime);
    console.log("taskDueDate", this.taskDueDate);
    console.log("taskDueTime", this.taskDueTime);
    console.log("taskDueTZ", this.taskDueTZ);
    console.log("taskDueTZDate", this.taskDueTZDate);
    console.log("taskDueTZTime", this.taskDueTZTime);
    console.log("taskTargetDate", this.taskTargetDate);
    console.log("taskTargetTime", this.taskTargetTime);
    console.log("taskTargetTZ", this.taskTargetTZ);
    console.log("taskTargetTZDate", this.taskTargetTZDate);
    console.log("taskTargetTZTime", this.taskTargetTZTime);
    console.log("taskExeTime", this.taskExeTime);
    console.log("taskExeTZ", this.taskExeTZ);
    console.log("taskExeTZTime", this.taskExeTZTime);
    console.log("taskRepWeekdays", this.taskRepWeekdays);
    console.log("taskRepWeeks", this.taskRepWeeks);
    console.log("taskRepMonths", this.taskRepMonths);
    console.log("taskRepMonthDay", this.taskRepMonthDay);
    console.log("taskRelDate", this.taskRelDate);
    console.log("taskOrigin", this.taskOrigin);
  }
}
