<div class="samPane">
  <div class="samPaneHeader">
    <h4 class="samPaneTitle">Profile</h4>
  </div>
  
  <mat-tab-group #profileTabs
    animationDuration="333ms"
    [mat-stretch-tabs]=false
    [selectedIndex]="selectedTab">

    <!-- PROFILE TAB -->

    <mat-tab label="Profile">

      <div class="mt-3 ml-3 mr-3">
        <mat-card>
          <mat-card-header class="mb-2">
            <mat-card-title>User Profile</mat-card-title>
            <mat-card-subtitle>Your user profile details:</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="row mt-2 ml-1">
              <div class="ml-1"><b>Profile ID:</b></div>
              <div class="ml-2">{{this.TQsession?.id}}</div>
            </div>
            <div class="row mt-2 ml-1">
              <div class="ml-1"><b>Profile E-Mail:</b></div>
              <div class="ml-2">{{this.TQsession?.email}}</div>
            </div>
            <div class="row mt-2 ml-1">
              <div class="ml-1"><b>E-Mail Verification:</b></div>
              <div class="ml-2">
                <span *ngIf="(this.TQsession?.email_verified==true)"
                  class="profile-icon-big green">
                  <fa-icon [icon]="faCheckCircle" title="Email verified"></fa-icon>
                  Your email has been verified.
                </span>
                <span *ngIf="(this.TQsession?.email_verified==false)"
                  class="profile-icon-big red">
                  <fa-icon [icon]="faExclamationCircle" title="Email not verified"></fa-icon>
                  Your email has NOT been verified.
                </span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div *ngIf="!tqSession.hasEmailVerified()"
        class="border border-danger mt-3 ml-4 mr-4 p-3">
        <h5>Verify your email</h5>
        <p><b>1. </b>Check your email inbox for a message from TaskQuark and click on the verification link in that email.</p>
        <p><b>2. </b>Then click this button below to complete the verification process.</p>
        <button
          class="btn btn-success mb-2"
          (click)="checkEmailVerification()">
          Complete the verification
        </button>
        <hr>
        <p><em>If you did not receive any email, click the button below:</em></p>
        <button
          [disabled]="sentVerificationEmail || this.TQsession?.email_verified"
          class="btn btn-warning mb-4"
          (click)="sendVerificationEmail()">
          Resend verification email
        </button>
    </div>

      <div class="mt-3 ml-3 mr-3">
        <mat-card *ngIf="tqSession.hasEmailVerified() && this.TQsession.account" 
          class="profile-card">
          <mat-card-header class="mb-2">
            <mat-card-title>Associated Account</mat-card-title>
            <mat-card-subtitle>Your profile is associated with this account:</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="row mt-2 ml-1">
              <div class="ml-1"><b>Account ID:</b></div>
              <div class="ml-2">{{this.TQsession?.account?.id}}</div>
            </div>
            <div class="row mt-2 ml-1">
              <div class="ml-1"><b>Account Owner:</b></div>
              <div class="ml-2">{{this.TQsession?.account?.email}}</div>
            </div>
            <div class="row mt-2 ml-1">
              <div class="ml-1"><b>Account Type:</b></div>
              <div class="ml-2">{{this.TQsession?.account?.type}}</div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="border border-danger p-3 mt-4 ml-4 mr-4">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>Delete your profile</h5>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="!isCanceledSubscription">
              <p>To delete your profile you must first cancel your subscription in the Account tab.</p>
            </div>
            <div *ngIf="isCanceledSubscription">
              <p>To delete your profile and your application data, first mark the checkbox, and then press the button below.</p>
              <p>
                <p-checkbox 
                  class="ml-3 mr-1"
                  [binary]="true"
                  [(ngModel)]="clickedProfileDelete"
                />
                <em><span class="text-danger"> I understand that this operation is <b>irreversible</b> and my profile and all my application data will be deleted. Financial and tax related information can be retained for legal purposes.</span></em>
              </p>
              <p>After pressing the button you will be logged out from the application.</p>
              <button
                [disabled]="!clickedProfileDelete"
                class="btn btn-danger mr-2"
                (click)="deleteProfile();">
                Delete my profile
              </button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-tab>
    

    <!-- ACCOUNT TAB -->
    
    <mat-tab *ngIf="tqSession.hasEmailVerified()"
      label="Account">

      <div class="mt-3 ml-3 mr-3">
        <mat-card>
          <mat-card-header class="mb-2">
            <mat-card-title>Account</mat-card-title>
            <mat-card-subtitle>Your account details:</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="row mt-2 ml-1">
              <div class="ml-1"><b>Account ID:</b></div>
              <div class="ml-2">{{this.TQsession?.account?.id}}</div>
            </div>
            <div class="row mt-2 ml-1">
              <div class="ml-1"><b>Account E-Mail:</b></div>
              <div class="ml-2">{{this.TQsession?.account?.email}}</div>
            </div>
            <div class="row mt-2 ml-1">
              <div class="ml-1"><b>Account Type:</b></div>
              <div class="ml-2">{{this.TQsession?.account?.type}}</div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="mt-3 ml-3 mr-3">
        <mat-card>
          <mat-card-header class="mb-2">
            <mat-card-title>Last Subscription</mat-card-title>
            <mat-card-subtitle>Your last subscription details:</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="row mt-2 ml-1">
              <div class="ml-1"><b>Subscription ID:</b></div>
              <div class="ml-2">{{appState.lastSubscription?.id}}</div>
            </div>
            <div class="row mt-2 ml-1">
              <div class="ml-1"><b>Subscription Status:</b></div>
              <div class="ml-2">
                <span *ngIf="isValidSubscription"
                  class="profile-icon-big green">
                  <fa-icon [icon]="faCheckCircle" title="Active subscription"></fa-icon>
                  The last subscription is active.
                </span>
                <span *ngIf="!isValidSubscription"
                  class="profile-icon-big red">
                  <fa-icon [icon]="faExclamationCircle" title="Inactive subscription"></fa-icon>
                  There is no active subscription. Choose your subscription plan below.
                </span>
                <br>
                <span *ngIf="isValidSubscription && isCanceledSubscription"
                  class="profile-icon-big red">
                  <fa-icon [icon]="faExclamationCircle" title="Canceled subscription"></fa-icon>
                  The last subscription was canceled and will not be renewed at the end of the subscription period.
                </span>
                <br>
                <span *ngIf="isValidSubscription && isUnpaidSubscription"
                  class="profile-icon-big red">
                  <fa-icon [icon]="faExclamationCircle" title="Unpaid subscription"></fa-icon>
                  The last subscription is not yet paid.
                </span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="mt-3 ml-3 mr-3">

        <div class="border border-success p-3 mt-3 ml-3 mr-3">
          <mat-accordion>
            <mat-expansion-panel #subscriptionsExpansionPanel 
              [expanded]="!isValidSubscription">
              <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h5>Change your subscription</h5>
                  </mat-panel-title>
              </mat-expansion-panel-header>

              <p>Choose your new subscription plan among the choices below.</p>
              <p>After clicking the <b>Subscribe</b> button your current subscription will be canceled and a new one created.<br>
                If a payment is required, you will be redirected to the checkout page.<br>
                A credit for the unused time on the current subscription, if any, will be applied to next payments.</p>

              <table>
                <tr style="vertical-align: top;">

                  <!-- PROFESSIONAL ACCCOUNT PLANS -->
                  <td>
                    <div class="mt-3">
                      <h5>Professional Account</h5>
             
                      <div *ngIf="paidPlanAvailable && freePlanAvailable"
                        class="alert alert-info" role="alert">
                        You are entitled to a Free Professional Subscription!
                      </div>

                      <div *ngIf="trialPlanAvailable"
                        class="plan-card">
                        <h5>Trial subscription</h5>
                        <p><b>30 days free</b></p>
                        <h5><b>$0</b></h5>
                        <p>no payment required<br>&nbsp;</p>
                        <button
                          class="btn btn-success mr-2"
                          (click)="addSubscription('2501-professional-trial')">
                          Subscribe
                        </button>
                      </div>
                      <div *ngIf="freePlanAvailable"
                        class="plan-card">
                        <h5>Free subscription</h5>
                        <p><b>$0.00/month</b></p>
                        <h5><b>$0</b></h5>
                        <p>no payment required<br>&nbsp;</p>
                        <button
                          class="btn btn-success mr-2"
                          (click)="addSubscription('2401-professional-free')">
                          Subscribe
                        </button>
                      </div>
                      <div *ngIf="paidPlanAvailable"
                        class="plan-card">
                        <h5>1 month subscription</h5>
                        <p><b>$6.00/month</b></p>
                        <h5><b>$6</b></h5>
                        <p>billed every month<br><i>taxes not included</i></p>
                        <button
                          class="btn btn-success mr-2"
                          (click)="addSubscription('2401-professional-1m')">
                          Subscribe
                        </button>
                      </div>
                      <div *ngIf="paidPlanAvailable"
                        class="plan-card">
                        <h5>12 month subscription</h5>
                        <p><b>$5.00/month</b></p>
                        <h5><b>$60</b></h5>
                        <p>billed every 12 months<br><i>taxes not included</i></p>
                        <button
                          class="btn btn-success mr-2"
                          (click)="addSubscription('2401-professional-1y')">
                          Subscribe
                        </button>
                      </div>

                    </div>
      
                  </td>
                </tr>
              </table>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div *ngIf="isCancelableSubscription"
          class="border border-danger mt-3 ml-3 mr-3 p-3">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h5>Cancel your subscription</h5>
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <p>To cancel your subscription, mark the checkbox below, and then press the <b>Cancel</b> button.</p>
              <p>
                <p-checkbox 
                  class="ml-3 mr-2"
                  [binary]="true"
                  [(ngModel)]="clickedSubscriptionCancel"
                />
                <span class="text-danger"><em>I understand that by canceling my subscription, it will not be renewed at the end of this subscription period.</em></span>
              </p>
              <button
                [disabled]="!clickedSubscriptionCancel"
                class="btn btn-danger mr-2 mb-2"
                (click)="cancelSubscription()">
                Cancel
              </button>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <div class="mt-4 ml-3 mr-3">
        <mat-card>
          <mat-card-header class="mb-2">
            <mat-card-title>Subscription History</mat-card-title>
            <mat-card-subtitle>Your subscriptions:</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="row table-bordered mt-2 ml-1 mr-1 p-1">
              <div class="col-3"><b>Plan</b></div>
              <div class="col-2"><b>Start date (UTC)</b></div>
              <div class="col-2"><b>Canceled (UTC)</b></div>
              <div class="col-2"><b>Last paid (UTC)</b></div>
              <div class="col-2"><b>End date (UTC)</b></div>
            </div>
            <div id="subscription-col1-{{s.id}}" 
              class="row border ml-1 mr-1 p-1"
              *ngFor="let s of TQsubscriptions; let i = index"
              [ngStyle]="{
                'color': i==0 ? (isValidSubscription ? 'green' : 'red' ) : 'black',
              }"
              >
              <div class="col-3">{{s.plan}}</div>
              <div class="col-2">{{s.startDate | date:'yyyy-MM-dd HH:mm:ss'}}</div>
              <div class="col-2">{{s.dateCanceled | date:'yyyy-MM-dd HH:mm:ss'}}</div>
              <div class="col-2">{{s.lastPayment |  date:'yyyy-MM-dd HH:mm:ss'}}</div>
              <div class="col-2">{{s.endDate | date:'yyyy-MM-dd HH:mm:ss'}}</div>
            </div>  
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>


    <!-- PREFERENCES TAB -->

    <mat-tab label="Preferences"
      *ngIf="tqSession.hasEmailVerified() && isValidSubscription">

      <div class="mt-3 ml-3 mr-3">

        <mat-card>
          <mat-card-header>
            <mat-card-title>Login</mat-card-title>
            <mat-card-subtitle>Set your login preferences</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <label for="prefLoginStartPad"
              class="TQ-checkbox-label mt-3 ml-3 mr-1">
              Starting pad on login:
            </label>
            <select #PrefLoginStartPad 
              type="checkbox"
              [(ngModel)]="prefLoginStartPad">
              <option value="todopad">Todopad</option>
              <option value="weekpad">Weekpad</option>
              <option value="monthpad">Monthpad</option>
              <option value="workpad">Workpad</option>
            </select>
          </mat-card-content>
        </mat-card>

        <hr>
        
        <mat-card>
          <mat-card-header>
            <mat-card-title>Logout</mat-card-title>
            <mat-card-subtitle>Set your logout preferences</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <label for="prefLoginStartPad"
              class="TQ-checkbox-label mt-3 ml-3 mr-1">
              Log out after inactive for:
            </label>
            <select #PrefLogoutAfterInactiveFor
              type="checkbox"
              [(ngModel)]="prefLogoutAfterInactiveFor">
              <option value="0">Never</option>
              <option value="15">15 minutes</option>
              <option value="30">30 minutes</option>
              <option value="60">1 hour</option>
              <option value="120">2 hours</option>
              <option value="240">4 hours</option>
              <option value="480">8 hours</option>
              <option value="1440">24 hours</option>
            </select>
          </mat-card-content>
        </mat-card>

        <hr>

        <mat-card>
          <mat-card-header>
            <mat-card-title>Calendars</mat-card-title>
            <mat-card-subtitle>Set your calendar preferences</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <label for="PrefLocTimeZone" 
              class="TQ-checkbox-label mt-3 ml-3 mr-1">
              Time zone:
            </label>
            <tq-timezones-widget
              [(timezone)]="prefLocTimeZone"
            ></tq-timezones-widget>
            <br>
            <label for="PrefLocDateFormat" 
              class="TQ-checkbox-label mt-3 ml-3 mr-1">
              Display dates as:
            </label>
            <select #PrefLocDateFormat 
              [(ngModel)]="prefLocDateFormat">
              <option value="YYYY-MM-DD">YYYY-MM-DD</option>
              <option value="MM/DD/YYYY">MM/DD/YYYY</option>
              <option value="DD-MM-YYYY">DD-MM-YYYY</option>
            </select>
            <br>
            <label for="PrefLocTimeFormat" 
              class="TQ-checkbox-label mt-3 ml-3 mr-1">
              Display time as:
            </label>
            <select #PrefLocTimeFormat 
              [(ngModel)]="prefLocTimeFormat">
              <option value="24h">hh:mm (24h)</option>
              <option value="12h">hh:mm (AM/PM)</option>
            </select>
            <br>
            <label for="PrefLocWeekStart" 
              class="TQ-checkbox-label mt-3 ml-3 mr-1">
              Start the week on:
            </label>
            <select #PrefLocWeekStart 
              [(ngModel)]="prefLocWeekStart">
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
              <option value="Monday">Monday</option>
            </select>
            <br>
              <label for="PrefLocWeekDays" 
                class="TQ-checkbox-label mt-3 ml-3 mr-1">
                Weekend days:
              </label>
              <div style="display:inline-block">
                <tq-weekdays-widget
                  [(weekdays)]="prefLocWeekendDays"
                  [weekstart]="prefLocWeekStart"
              ></tq-weekdays-widget>
            </div>   
          </mat-card-content>
        </mat-card>

        <hr>

        <mat-card>
          <mat-card-header>
            <mat-card-title>TaskRadar</mat-card-title>
            <mat-card-subtitle>Set your TaskRadar preferences</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div style="display:inline-block">
              <p-checkbox 
                class="ml-3 mr-1"
                [binary]="true"
                [(ngModel)]="prefTaskRadarEnabled"
              />
              <label for="prefTaskRadarEnabled" 
                class="TQ-checkbox-label mt-3 ml-1 mr-1">
                Send me a
              </label>
              <select type="checkbox"
                #PrefTaskRadarFreq [(ngModel)]="prefTaskRadarFreq"
                class="mr-1">
                <option value="daily">daily</option>
                <option value="weekly">weekly</option>
                <option value="monthly">monthly</option>
              </select>
              <b>TaskRadar email, with a range of </b>
              <select type="checkbox"
                #PrefTaskRadarRange [(ngModel)]="prefTaskRadarRange">
                <option value="3d">3 days</option>
                <option value="7d">7 days</option>
                <option value="14d">14 days</option>
                <option value="30d">30 days</option>
                <option value="60d">60 days</option>
              </select>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="mt-4 mb-2">
          <button id="PrefSaveButton" 
            title="Save (Ctrl-S)" 
            class="btn btn-success ml-2"
            (click)="savePreferences()">
            Save
          </button>
        </div>

      </div>

    </mat-tab>


    <!-- CONFIGURATION TAB -->

    <mat-tab *ngIf="tqSession.hasEmailVerified() && isValidSubscription"
      label="Configuration">

      <div class="mt-3 ml-3 mr-3">

        <mat-card>
          <mat-card-header>
            <mat-card-title>Roles</mat-card-title>
            <mat-card-subtitle>Configure your profile roles</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <label for="CfgRoleActive" 
              class="TQ-checkbox-label mt-4 ml-3 mr-2">
              Active role:
            </label>
            <tq-role-selector
              [(roleId)]="cfgRoleSelectedId"
            ></tq-role-selector>
          </mat-card-content>
        </mat-card>

        <hr>
        
        <mat-card>
          <mat-card-header>
            <mat-card-title>Workpad</mat-card-title>
            <mat-card-subtitle>Configure your Workpad</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <label for="PrefWorkpadNumCols" 
              class="TQ-checkbox-label mt-4 ml-3 mr-1">
              Number of columns:
            </label>
            <select type="checkbox"
              #PrefWorkpadNumCols [(ngModel)]="prefWorkpadNumCols">
              <option value="5">5</option>
              <option value="4">4</option>
              <option value="3">3</option>
              <option value="2">2</option>
              <option value="1">1</option>
            </select>

            <p class="mt-3 mb-2 ml-3"><b>Workpad columns for desktop:</b></p>
            <div style="display:inline-block">
              <label for="PrefWorkpadCol1" 
                class="ml-3 mr-1">
                Column 1:
              </label>
              <select type="checkbox"
                #PrefWorkpadCol1 [(ngModel)]="prefWorkpadCol1">
                <option value="any">all</option>
                <option value="planning">planning</option>
                <option value="todo">todo</option>
                <option value="doing">doing</option>
                <option value="waiting">waiting</option>
                <option value="stopped">stopped</option>
                <option value="done">done</option>
                <option value="canceled">canceled</option>
              </select>
            </div>
            <div style="display:inline-block">
            <label for="PrefWorkpadCol2" 
              class="ml-3 mr-1"> 
              Column 2:
            </label>
            <select type="checkbox"
              #PrefWorkpadCol2 [(ngModel)]="prefWorkpadCol2">
              <option value="any">all</option>
              <option value="planning">planning</option>
              <option value="todo">todo</option>
              <option value="doing">doing</option>
              <option value="waiting">waiting</option>
              <option value="stopped">stopped</option>
              <option value="done">done</option>
              <option value="canceled">canceled</option>
            </select>
            </div>
            <div style="display:inline-block">
            <label for="PrefWorkpadCol3" 
              class="ml-3 mr-1">
              Column 3:
            </label>
            <select type="checkbox"
              #PrefWorkpadCol3 [(ngModel)]="prefWorkpadCol3">
              <option value="any">all</option>
              <option value="planning">planning</option>
              <option value="todo">todo</option>
              <option value="doing">doing</option>
              <option value="waiting">waiting</option>
              <option value="stopped">stopped</option>
              <option value="done">done</option>
              <option value="canceled">canceled</option>
            </select>
            </div>
            <div style="display:inline-block">
            <label for="PrefWorkpadCol4" 
              class="ml-3 mr-1">
              Column 4:
            </label>
            <select type="checkbox"
              #PrefWorkpadCol4 [(ngModel)]="prefWorkpadCol4">
              <option value="any">all</option>
              <option value="planning">planning</option>
              <option value="todo">todo</option>
              <option value="doing">doing</option>
              <option value="waiting">waiting</option>
              <option value="stopped">stopped</option>
              <option value="done">done</option>
              <option value="canceled">canceled</option>
            </select>
            </div>
            <div style="display:inline-block">
              <label for="PrefWorkpadCol5" 
                class="ml-3 mr-1">
                Column 5:
              </label>
              <select type="checkbox"
                #PrefWorkpadCol5 [(ngModel)]="prefWorkpadCol5">
                <option value="any">all</option>
                <option value="planning">planning</option>
                <option value="todo">todo</option>
                <option value="doing">doing</option>
                <option value="waiting">waiting</option>
                <option value="stopped">stopped</option>
                <option value="done">done</option>
                <option value="canceled">canceled</option>
              </select>
            </div>
      
            <p class="mt-3 mb-2 ml-3"><b>Workpad columns for mobile:</b></p>
            <div style="display:inline-block">
            <label for="PrefWorkpadCol1Mobile" 
              class="ml-3 mr-1">
              Column 1:
            </label>
            <select #PrefWorkpadCol1Mobile 
              type="checkbox"
              [(ngModel)]="prefWorkpadCol1Mobile">
              <option value="any">all</option>
              <option value="planning">planning</option>
              <option value="todo">todo</option>
              <option value="doing">doing</option>
              <option value="waiting">waiting</option>
              <option value="stopped">stopped</option>
              <option value="done">done</option>
              <option value="canceled">canceled</option>
            </select>
            </div>
            <div style="display:inline-block">
            <label for="PrefWorkpadCol2Mobile" 
              class="ml-3 mr-1">
              Column 2:
            </label>
            <select #PrefWorkpadCol2Mobile 
              type="checkbox"
              [(ngModel)]="prefWorkpadCol2Mobile">
              <option value="any">all</option>
              <option value="planning">planning</option>
              <option value="todo">todo</option>
              <option value="doing">doing</option>
              <option value="waiting">waiting</option>
              <option value="stopped">stopped</option>
              <option value="done">done</option>
              <option value="canceled">canceled</option>
            </select>
            </div>
            <div style="display:inline-block">
            <label for="PrefWorkpadCol3Mobile" 
              class="ml-3 mr-1">
              Column 3:
            </label>
            <select #PrefWorkpadCol3Mobile 
              type="checkbox"
              [(ngModel)]="prefWorkpadCol3Mobile">
              <option value="any">all</option>
              <option value="planning">planning</option>
              <option value="todo">todo</option>
              <option value="doing">doing</option>
              <option value="waiting">waiting</option>
              <option value="stopped">stopped</option>
              <option value="done">done</option>
              <option value="canceled">canceled</option>
            </select>
            </div>
            <div style="display:inline-block">
            <label for="PrefWorkpadCol4Mobile" 
              class="ml-3 mr-1">
              Column 4:
            </label>
            <select #PrefWorkpadCol4Mobile 
              type="checkbox"
              [(ngModel)]="prefWorkpadCol4Mobile">
              <option value="any">all</option>
              <option value="planning">planning</option>
              <option value="todo">todo</option>
              <option value="doing">doing</option>
              <option value="waiting">waiting</option>
              <option value="stopped">stopped</option>
              <option value="done">done</option>
              <option value="canceled">canceled</option>
            </select>
            </div>
            <div style="display:inline-block">
              <label for="PrefWorkpadCol5Mobile" 
                class="ml-3 mr-1">
                Column 5:
              </label>
              <select #PrefWorkpadCol5Mobile 
                type="checkbox"
                [(ngModel)]="prefWorkpadCol5Mobile">
                <option value="any">all</option>
                <option value="planning">planning</option>
                <option value="todo">todo</option>
                <option value="doing">doing</option>
                <option value="waiting">waiting</option>
                <option value="stopped">stopped</option>
                <option value="done">done</option>
                <option value="canceled">canceled</option>
              </select>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="mt-4 mb-2">
          <button id="PrefSaveButton" 
            title="Save (Ctrl-S)" 
            class="btn btn-success ml-2"
            (click)="savePreferences()">
            Save
          </button>
        </div>
      </div>

    </mat-tab>

  </mat-tab-group>

  <div *ngIf="samApp.onMobile"
    class="samPaneFooter">
  </div>
</div>
