import { Component, Input, Optional } from '@angular/core';

import * as TASK from 'src/app/models/task';

import { TQDateTimeService } from 'src/app/services/tqdatetime.service';

import { faCalendarCheck, faClock } from '@fortawesome/free-regular-svg-icons';
import { faThumbtack, faCaretLeft, faCaretRight, faCaretSquareLeft, faCaretSquareRight, faCaretSquareDown, faCaretSquareUp }
  from '@fortawesome/free-solid-svg-icons';

import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'tq-task-card',
  templateUrl: './tq-task-card.component.html',
  styleUrls: ['./tq-task-card.component.scss']
})
export class TQTaskCardComponent {
  faCalendarCheck = faCalendarCheck;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;
  faCaretSquareLeft = faCaretSquareLeft;
  faCaretSquareRight = faCaretSquareRight;
  faCaretSquareUp = faCaretSquareUp;
  faCaretSquareDown = faCaretSquareDown;  
  faClock = faClock;
  faFile = faFile;
  faRedo = faRedo;
  faThumbtack = faThumbtack;

  @Input() t: any;
  @Input() onDate: string;
  @Input() showProjectCode: boolean;
  @Input() showDuration: boolean;

  constructor 
  (
    public tqDT: TQDateTimeService,
  ) 
  {} 

  // TODO should be global
  getPriorityColor(priority: string)
  {
    switch (priority)
    {
      case "A": return 'red'
      case "B": return 'orange'
      case "C": return 'green'
      case "D": return 'blue'
      case "E": return 'grey'
    }
    return 'black'
  }

  isRepeatingTask(t: any)
  {
    return (TASK.hasRepetitionOnWeekdays(t) || TASK.hasRepetitionOnMonths(t) || TASK.hasRepetitionOnNumberDay(t))
  }

}
