import { environment } from 'src/environments/environment';

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

import { MenuItem } from 'primeng/api';

@Component({
  selector: 'tq-task-menu',
  templateUrl: './tq-task-menu.component.html',
  styleUrl: './tq-task-menu.component.scss'
})
export class TQTaskMenuComponent implements AfterViewInit
{
  @Input() target: ElementRef; 
  @Input() canAddTask: boolean;
  @Output() command = new EventEmitter<string>();
  
  menuItems: MenuItem[];
  
  constructor
  (
    private changeDetectorRef: ChangeDetectorRef,
  ) 
  {}


  ngAfterViewInit()
  {
    this.menuItems =
    [
      {
        label:'Set state...',
        icon:'pi pi-fw ',
        command: (event) => this.outputCommand(event),
        items:[
          {
            label:'done',
            command: (event) => this.outputCommand(event)
          },
          {
            label:'canceled',
            command: (event) => this.outputCommand(event)
          },
          {
            label:'stopped',
            command: (event) => this.outputCommand(event)
          },
          {
            label:'waiting',
            command: (event) => this.outputCommand(event)
          },
          {
            label:'doing',
            command: (event) => this.outputCommand(event)
          },
          {
            label:'todo',
            command: (event) => this.outputCommand(event)
          },
          {
            label:'planning',
            command: (event) => this.outputCommand(event)
          },
        ]
      },  
      {
        label:'Set priority...',
        icon:'pi pi-fw',
        items:[
          {
            label:'A',
            command: (event) => this.outputCommand(event),
          },
          {
            label:'B',
            command: (event) => this.outputCommand(event),
          },
          {
            label:'C',
            command: (event) => this.outputCommand(event),
          },
          {
            label:'D',
            command: (event) => this.outputCommand(event),
          },
          {
            label:'E',
            command: (event) => this.outputCommand(event),
          },
        ]
      },  
      {
        label:'Set pinned to...',
        icon:'pi pi-fw ',
        command: (event) => this.outputCommand(event),
        items:[
          {
            label:'current day',
            command: (event) => this.outputCommand(event)
          },
          {
            label:'next day',
            command: (event) => this.outputCommand(event)
          },
          {
            label:'not pinned',
            command: (event) => this.outputCommand(event)
          },
        ]
      },  
      {
        separator: true,
      },
      {
        label:'Edit',
        icon:'pi pi-fw pi-pencil',
        command: (event) => this.outputCommand(event)
      },  
      {
        label:'Duplicate',
        icon:'pi pi-fw pi-copy',
        command: (event) => this.outputCommand(event),
        disabled: this.canAddTask == false
      },
      {
        label:'Select',
        icon:'pi pi-fw pi-sun',
        command: (event) => this.outputCommand(event)
      },
      // {
      //   separator: true,
      // },
      // {
      //   label:'Delete',
      //   icon:'pi pi-fw pi-trash',
      //   command: (event) => this.outputCommand(event)
      // }
      {
        separator: true,
        visible: environment.samurai.features.allowActivityLog
      },
      {
        label:'Log Activity',
        icon:'pi pi-fw pi-stopwatch',
        command: (event) => this.outputCommand(event),
        visible: environment.samurai.features.allowActivityLog
      },
    ]
      
    this.changeDetectorRef.detectChanges();
  } 

  
  outputCommand (event: any)
  {
    this.command.emit(event.item.label); 
  }

}
