<div class="samPane">
  <div class="samPaneHeader">
    <h4 class="samPaneTitle">
      Task
      <a href="https://docs.taskquark.com/tasks.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
  </div>

  <div *ngIf="!canAddTask"
    class="alert alert-danger" role="alert">
    You have reached the maximum number of tasks for your subscription plan.
  </div>

  <div *ngIf="!canEditTask"
    class="alert alert-danger" role="alert">
    You do not have permissions to modify this task.
  </div>

  <div class="ml-2 mr-2">

    <!-- TASK HEADER  -->

    <div>
      <label for="TaskProject" 
        class="TQ-checkbox-label mt-3 ml-2 mr-2">
        Project:
      </label>
      <p-treeSelect 
        [options]="projectsNodes"
        [(ngModel)]="selectedProject"
        (onNodeSelect)="projectNodeSelect($event)"
        (onNodeUnselect)="projectNodeUnselect($event)"
        >
      </p-treeSelect>
    </div>  

    <div>
      <label for="TaskTitle"
        class="TQ-checkbox-label mt-3 ml-2 mr-1">
        Title:
      </label>
      <input #TaskTitle id="TaskTitle"
        type="Text" 
        class="ml-1 pl-1 pr-1 samMandatory" 
        [ngClass]="{'col-sm-12' : this.samApp.onMobile, 'col-11' : !this.samApp.onMobile}" 
        placeholder="Enter a task title..." maxlength=255 
        [(ngModel)]="taskTitle">

      <button 
        title="Save (Ctrl-S)" 
        class="btn btn-sm btn-success mt-1 mb-1 ml-1 pa-1" 
        [disabled]="!canAddTask || !canEditTask || clicked || this.taskTitle==''" 
        (click)="clicked=true; addTask()">
        {{ !this.taskId ? "Add" : "Save" }}
      </button>
    </div>

    <mat-tab-group 
      class="mt-3 ml-2 mr-2"
      [mat-stretch-tabs]=false
      [(selectedIndex)]="selectedTab">

      <!-- TASK TAB -->

      <mat-tab label="Task">

        <div style="display:inline-block">
          <label for="TaskStatus" 
            class="TQ-checkbox-label mt-4 ml-4 mr-2">
            State: 
          </label>
          <select type="checkbox" #TaskStatus [(ngModel)]="taskStatus">
            <option value="planning">planning</option>
            <option value="todo">to do</option>
            <option value="doing">doing</option>
            <option value="waiting">waiting</option>
            <option value="stopped">stopped</option>
            <option value="canceled">canceled</option>
            <option value="done">done</option>
          </select>
        </div>
    
        <div style="display:inline-block">
          <label for="TaskPriority" 
            class="TQ-checkbox-label mt-4 ml-4 mr-2"> 
            Priority: 
          </label>
          <select type="checkbox" #TaskPriority [(ngModel)]="taskPriority">
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
            <option value="E">E</option>
          </select>
        </div>

        <mat-tab-group 
          class="mt-2"
          [mat-stretch-tabs]=false
          [(selectedIndex)]="selectedTab2">

          <!-- DESCRIPTION TAB -->

          <mat-tab label="Description">
            <div>
              <p-editor #TaskDescription 
                [(ngModel)]="taskDescription" 
                [style]="{'white-space':'pre'}"
                placeholder="Enter a description for the task here..."
                ngDefaultControl>
              </p-editor>
            </div>
          </mat-tab>

          <!-- SCHEDULE TAB -->

          <mat-tab label="Schedule">

            <label class="TQ-checkbox-label mt-3 ml-2 mr-1"> Task Dates: </label>
            <br>

            <div style="display:inline-block">
              <label for="StartWorkDatePicker" class="TQ-checkbox-label mt-3 ml-3 mr-2">Start Date:&nbsp;</label>
              <tq-date-widget #TaskStartWorkDate
                [(date)]="taskStartWorkTZDate"
                [maxDate]="taskEndWorkTZDate"
                (dateChange)="updateStartWorkDateTime()"
              ></tq-date-widget>  
              <button 
                class="badge TQ-clear-button ml-1" 
                [disabled]="clicked" 
                (click)="clearStartWorkDate()">
                X
              </button>
            </div>
            <div *ngIf="taskStartWorkTZDate" 
              style="display:inline-block"> 
              <tq-time-widget class="ml-3" 
                [(time)]="taskStartWorkTZTime"
                [timeFormat]=this.appState.prefLocTimeFormat
                (change)="updateStartWorkDateTime()"
              ></tq-time-widget>
              <tq-timezones-widget *ngIf="taskStartWorkTZTime" 
                [(timezone)]="taskStartWorkTZ"
                [allowProfile]="true"
                [allowFloating]="true"
                (change)="updateStartWorkDateTime()"
              ></tq-timezones-widget>
              <button 
              class="badge TQ-clear-button ml-1" 
                [disabled]="clicked" 
                (click)="clearStartWorkTime()">
                X
              </button>
              <tq-show-date-time-widget *ngIf="taskStartWorkTZTime && taskStartWorkTZ != null && taskStartWorkTZ != 'N'"
                [date]="taskStartWorkDate"
                [dateFormat]=this.appState.prefLocDateFormat
                [time]="taskStartWorkTime"
                [timeFormat]=this.appState.prefLocTimeFormat
              ></tq-show-date-time-widget>
            </div>

            <!-- <br *ngIf="this.samApp.onMobile">
            <div style="display:inline-block">
              <button 
                class="badge TQ-action-button ml-3" 
                title="Copy end date to start date"
                [disabled]="clicked" 
                (click)="copyEndToStart()">
                End date
              </button>
            </div>
            <div style="display:inline-block">
              <button 
                class="badge TQ-action-button ml-1" 
                title="Copy due date to start date"
                [disabled]="clicked" 
                (click)="copyDueToStart()">
                Due date
              </button>
            </div> -->

            <br>

            <div style="display:inline-block">
              <label for="EndWorkDatePicker" class="TQ-checkbox-label mt-2 ml-3 mr-2">End Date:&nbsp;&nbsp;&nbsp;</label>
              <tq-date-widget #TaskEndWorkDate
                [(date)]="taskEndWorkTZDate"
                [minDate]="taskStartWorkTZDate"
                (dateChange)="updateEndWorkDateTime()"
              ></tq-date-widget>
              <button 
                class="badge TQ-clear-button ml-1" 
                [disabled]="clicked" 
                (click)="clearEndWorkDate()">
                X
              </button>
            </div>
            <div *ngIf="taskEndWorkTZDate" 
              style="display:inline-block">
              <tq-time-widget class="ml-3"  
                [(time)]="taskEndWorkTZTime"
                [timeFormat]=this.appState.prefLocTimeFormat
                (change)="updateEndWorkDateTime()"
              ></tq-time-widget>
              <tq-timezones-widget *ngIf="taskEndWorkTZTime"  
                [(timezone)]="taskEndWorkTZ"
                [allowProfile]="true"
                [allowFloating]="true"
                (change)="updateEndWorkDateTime()"
              ></tq-timezones-widget>
              <button 
                class="badge TQ-clear-button ml-1" 
                [disabled]="clicked" 
                (click)="clearEndWorkTime()">
                X
              </button>
              <tq-show-date-time-widget *ngIf="taskEndWorkTZTime && taskEndWorkTZ != null && taskEndWorkTZ != 'N'"
                [date]="taskEndWorkDate"
                [dateFormat]=this.appState.prefLocDateFormat
                [time]="taskEndWorkTime"
                [timeFormat]=this.appState.prefLocTimeFormat
              ></tq-show-date-time-widget>
            </div>

            <!-- <br *ngIf="this.samApp.onMobile">
            <div style="display:inline-block">
              <button 
                class="badge TQ-action-button ml-1" 
                title="Copy start date to end date"
                [disabled]="clicked" 
                (click)="copyStartToEnd()">
                Start date
              </button>
            </div>
            <div style="display:inline-block">
              <button 
                class="badge TQ-action-button ml-1" 
                title="Copy due date to end date"
                [disabled]="clicked" 
                (click)="copyDueToEnd()">
                Due date
              </button>
            </div> -->

            <br>

            <div style="display:inline-block">
              <label for="TaskTargetDate" class="TQ-checkbox-label mt-3 ml-3 mr-0">Target Date:</label>
              <tq-date-widget #TaskTargetDate
                [(date)]="taskTargetTZDate"
                (dateChange)="updateTargetDateTime()"
              ></tq-date-widget>
              <button 
                class="badge TQ-clear-button ml-1" 
                [disabled]="clicked" 
                (click)="clearTargetDate()">
                X
              </button>
            </div>
            <div *ngIf="taskTargetTZDate" 
              style="display:inline-block">
              <tq-time-widget class="ml-3"  
                [(time)]="taskTargetTZTime"
                [timeFormat]=this.appState.prefLocTimeFormat
                (change)="updateTargetDateTime()"
              ></tq-time-widget>
              <tq-timezones-widget *ngIf="taskTargetTZTime" 
                [(timezone)]="taskTargetTZ"
                [allowProfile]="true"
                [allowFloating]="true"
                (change)="updateTargetDateTime()"
              ></tq-timezones-widget>
              <button 
                class="badge TQ-clear-button ml-1" 
                [disabled]="clicked" 
                (click)="clearTargetTime()">
                X
              </button>
              <tq-show-date-time-widget *ngIf="taskTargetTZTime && taskTargetTZ != null && taskTargetTZ != 'N'"
              [date]="taskTargetDate"
              [dateFormat]=this.appState.prefLocDateFormat
              [time]="taskTargetTime"
              [timeFormat]=this.appState.prefLocTimeFormat
              ></tq-show-date-time-widget>
            </div>

            <!-- <br *ngIf="this.samApp.onMobile">
            <div style="display:inline-block">
              <button 
                class="badge TQ-action-button ml-1" 
                title="Copy start date to target date"
                [disabled]="clicked" 
                (click)="copyStartToTarget()">
                Start date
              </button>
              <button 
                class="badge TQ-action-button ml-1" 
                title="Copy end date to target date"
                [disabled]="clicked" 
                (click)="copyEndToTarget()">
                End date
              </button>
              <button 
                class="badge TQ-action-button ml-1" 
                title="Copy due date to target date"
                [disabled]="clicked" 
                (click)="copyDueToTarget()">
                Due date
              </button>
            </div> -->

            <br>

            <div style="display:inline-block">
              <label for="TaskDueDate" class="TQ-checkbox-label mt-2 ml-3 mr-2">Due Date:&nbsp;&nbsp;</label>
              <tq-date-widget #TaskDueDate
                [(date)]="taskDueTZDate"
                (dateChange)="updateDueDateTime()"
              ></tq-date-widget>
              <button 
                class="badge TQ-clear-button ml-1" 
                [disabled]="clicked" 
                (click)="clearDueDate()">
                X
              </button>
            </div>
            <div *ngIf="taskDueTZDate" 
              style="display:inline-block">
              <tq-time-widget class="ml-3"  
                [(time)]="taskDueTZTime"
                [timeFormat]=this.appState.prefLocTimeFormat
                (change)="updateDueDateTime()"
              ></tq-time-widget>
              <tq-timezones-widget *ngIf="taskDueTZTime" 
                [(timezone)]="taskDueTZ"
                [allowProfile]="true"
                [allowFloating]="true"
                (change)="updateDueDateTime()"
              ></tq-timezones-widget>
              <button 
                class="badge TQ-clear-button ml-1" 
                [disabled]="clicked" 
                (click)="clearDueTime()">
                X
              </button>
              <tq-show-date-time-widget *ngIf="taskDueTZTime && taskDueTZ != null && taskDueTZ != 'N'"
                [date]="taskDueDate"
                [dateFormat]=this.appState.prefLocDateFormat
                [time]="taskDueTime"
                [timeFormat]=this.appState.prefLocTimeFormat
              ></tq-show-date-time-widget>
            </div>

            <!-- <br *ngIf="this.samApp.onMobile">
            <div style="display:inline-block">
              <button 
                class="badge TQ-action-button ml-1" 
                title="Copy start date to due date"
                [disabled]="clicked" 
                (click)="copyStartToDue()">
                Start date
              </button>
              <button 
                class="badge TQ-action-button ml-1" 
                title="Copy end date to due date"
                [disabled]="clicked" 
                (click)="copyEndToDue()">
                End date
              </button>
            </div> -->

            <br>

            <span *ngIf="taskOrigin" class="task-icon mr-1">
              {{taskOrigin}}
              <fa-icon [icon]="faCalendarCheck" ></fa-icon>

              <br>
            </span>

            <label class="TQ-checkbox-label mt-4 ml-2 mr-1">Work Schedule:</label>
            <br>

            <div style="display:inline-block">
              <label for="TaskRelDate" class="TQ-checkbox-label mt-3 ml-3 mr-2">Pin to:</label>
              <select type="checkbox" #TaskRelDate [(ngModel)]="taskRelDate">
                <option value="+0d">current day</option>
                <option value="+1d">next day</option>
                <option value="future">not pinned</option>
              </select>
            </div>
            <br>

            <div style="display:inline-block">
              <label class="TQ-checkbox-label mt-3 ml-3 mr-2">Work Time:</label>
              <tq-time-widget 
                [(time)]="taskExeTZTime"
                [timeFormat]=this.appState.prefLocTimeFormat
                (change)="updateExeTime()"
              ></tq-time-widget>
              <tq-timezones-widget *ngIf="taskExeTZTime"
                [(timezone)]="taskExeTZ"
                [allowProfile]="true"
                [allowFloating]="true"
                (change)="updateExeTime()"
              ></tq-timezones-widget>
              <button class="badge TQ-clear-button ml-1" 
                [disabled]="clicked" 
                (click)="clearExeTime()">
                X
              </button>
            </div>
            <!-- <br *ngIf="this.samApp.onMobile">
            <div style="display:inline-block">
              <button 
                class="badge TQ-action-button ml-1"
                title="Copy start time to execution time" 
                [disabled]="clicked" 
                (click)="copyStartTimeToExeTime()">
                Start time
              </button>
            </div>
            <div style="display:inline-block">
              <button 
                class="badge TQ-action-button ml-1"
                title="Copy end time to execution time" 
                [disabled]="clicked" 
                (click)="copyEndTimeToExeTime()">
                End time
              </button>
            </div>
            <div style="display:inline-block">
              <button 
                class="badge TQ-action-button ml-1" 
                title="Copy due time to execution time"
                [disabled]="clicked" 
                (click)="copyDueTimeToExeTime()">
                Due time
              </button>
            </div> -->

            <div *ngIf="true || this.taskExeTime"
              style="display:inline-block">
              <label class="TQ-checkbox-label mt-2 ml-3 mr-2">Work Duration:</label>
              <tq-duration-widget 
                [(duration)]="taskExeDuration"
              ></tq-duration-widget>
              <button class="badge TQ-clear-button ml-1" [disabled]="clicked" (click)="clearExeDuration()">
                X
              </button>
            </div>
            <br>

            <label class="TQ-checkbox-label mt-3 ml-3 mr-2">Weekday:</label>
            <div style="display:inline-block"
              [ngClass]="{'ml-2': this.samApp.onMobile}">
              <tq-weekdays-widget 
                [(weekdays)]="taskRepWeekdays"
                [weekstart]="this.appState.prefLocWeekStart"
              ></tq-weekdays-widget>
            </div>
            <div style="display:inline-block"
              [ngClass]="{'mt-1 ml-2': this.samApp.onMobile}" >
              <button class="badge TQ-clear-button mr-1" [disabled]="clicked" (click)="clearRepWeekdays()">
                X
              </button>
              <button 
                class="badge TQ-action-button ml-1" 
                title="Select all workdays"
                [disabled]="clicked" 
                (click)="toggleWorkDays()">
                Workdays
              </button>
              <button 
                class="badge TQ-action-button ml-1" 
                title="Select all weekend days"
                [disabled]="clicked" 
                (click)="toggleWeekend()">
                Weekend
              </button>
            </div>
            <label class="TQ-checkbox-label mt-3 ml-1 mr-2"></label>
            <div style="display:inline-block"
              [ngClass]="{'ml-2': this.samApp.onMobile}">
              <tq-weeks-widget 
                [(weeks)]="taskRepWeeks"
              ></tq-weeks-widget>
            </div>
            <div style="display:inline-block"
              [ngClass]="{'mt-1 ml-2': this.samApp.onMobile}" >
              <button class="badge TQ-clear-button mr-1" [disabled]="clicked" (click)="clearRepWeeks()">
                X
              </button>
            </div>

            <br>

            <label class="TQ-checkbox-label mt-3 ml-3 mr-2">Day:</label>
            <div style="display:inline-block">
              <select #TaskRepMonthDay type="checkbox" [(ngModel)]="taskRepMonthDay">
                <option *ngFor="let d of getRepMonthDays();" value="{{d}}">{{d}}
                </option>
              </select>
            </div>
            <div style="display:inline-block"
              [ngClass]="{'mt-1': this.samApp.onMobile}" >
              <button 
                class="badge TQ-clear-button" 
                [disabled]="clicked" 
                (click)="clearRepMonthDays()">
                X
              </button>
            </div>

            <label class="TQ-checkbox-label mt-3 ml-4 mr-2">Month:</label>
            <div style="display:inline-block">
              <p-selectButton [multiple]="true" 
                [options]="moy" optionLabel="name" optionValue="value"
                [(ngModel)]="taskRepMonths">
              </p-selectButton>
            </div>
            <div style="display:inline-block"
              [ngClass]="{'mt-1 ml-1': this.samApp.onMobile}" >
              <button 
                class="badge TQ-clear-button" 
                [disabled]="clicked" 
                (click)="clearRepMonths()">
                X
              </button>

            </div>
          </mat-tab>
        
        </mat-tab-group>

        <hr />

        <div *ngIf="this.selectedTab2 != 2"
          class="mt-1 mb-2"
        >
          <button id="TaskSaveButton" 
            title="Save (Ctrl-S)" 
            class="btn btn-success ml-1" 
            [disabled]="!canAddTask || !canEditTask || clicked || this.taskTitle==''" 
            (click)="clicked=true; addTask()">
            {{ !this.taskId ? "Add" : "Save" }}
          </button>
          <button id="TaskCancelButton" 
            title="Cancel (ESC)" 
            class="btn btn-warning ml-1" 
            [disabled]="clicked"
            (click)="clicked=true; cancelTask()">
            Cancel
          </button>
          <button id="TaskDuplicateButton" 
            *ngIf="this.taskId != 0" 
            title="Duplicate (Ctrl-D)" 
            class="btn btn-success ml-3"
            [disabled]="!canAddTask || !canEditTask || clicked" 
            (click)="clicked=true; duplicateTask()">
            Duplicate
          </button>
          <button 
            *ngIf="this.taskId != 0" 
            [disabled]="!canEditTask || clicked" 
            class="btn btn-danger ml-2 mr-1" 
            style="float:right"
            (click)="clicked=true; confirmDelete();">
            Delete
          </button>
        </div>
          </mat-tab>

      <!-- ACTIVITY TAB -->

      <mat-tab label="Activity"
        *ngIf="samApp.getFeatures().allowActivityLog"
      >

        <label class="TQ-checkbox-label mt-4 ml-3">Task Activity Summary:</label>

        <div>
          <label for="ActivityReportStartDate" 
            class="TQ-checkbox-label mt-3 ml-4 mr-1">
            From date:
          </label>
          <tq-date-widget 
            [(date)]="activityReportStartDate"
            [maxDate]="activityReportEndDate"
            (dateChange)="updateActivityReportStartDate()"
          ></tq-date-widget>
          <button 
            class="badge TQ-clear-button ml-1" 
            [disabled]="clicked" 
            (click)="clearActivityReportStartDate()">
            X
          </button>

          <label for="ActivityReportEndDate" 
            class="TQ-checkbox-label mt-3 ml-4 mr-1">
            to:
          </label>
          <tq-date-widget 
            [(date)]="activityReportEndDate"
            [minDate]="activityReportStartDate"
            (dateChange)="updateActivityReportEndDate()"
          ></tq-date-widget>
          <button 
            class="badge TQ-clear-button ml-1" 
            [disabled]="clicked" 
            (click)="clearActivityReportEndDate()">
            X
          </button>
        </div>

        <mat-card class="m-2">
          <mat-card-content>

            <div class="row m-1">
              <div class="ml-1"><b>Number of entries: </b>{{activities_number}}</div>
              <div class="ml-4"><b>Total duration: </b>{{tqDT.minutesToDuration(activities_time)}}</div>
            </div>

            <p-table *ngIf="activities && activities.length"
              [value]="activities" 
              sortField="startDate" [sortOrder]="-1"
              rowGroupMode="rowspan" groupRowsBy="startDate"
              styleClass="p-datatable-gridlines"
              [paginator]="true"
              [rows]="15"
              [rowsPerPageOptions]="[15, 30]"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th class="p-2" style="width: 110px; text-align:center;"
                    pSortableColumn="startDate">
                    Date
                    <p-sortIcon field="startDate"></p-sortIcon>
                  </th>
                  <th class="p-2" style="width: 70px">Entries</th>
                  <th class="p-2" style="width: 75px">Duration</th>
                  <th class="p-2">Description</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-act let-rowgroup="rowgroup" let-rowspan="rowspan">
                <tr>
                  <td class="p-1" style="text-align:center; vertical-align:top;">
                    {{ tqDT.formatDateFromISO(act.startDate) }}
                  </td>
                  <td class="p-1">
                    {{ act.numAct }}
                  </td>
                  <td class="p-1">
                    <span *ngIf="act.sumValue && act.sumValue !== 0">{{ tqDT.minutesToDuration(act.sumValue) }}</span>
                  </td>
                  <td class="p-1">
                  </td>
                </tr>
              </ng-template>
            </p-table>
            
          </mat-card-content>
        </mat-card>   

        <div class="mt-2">
          <button id="TaskActivityButton" 
            *ngIf="this.taskId != 0" 
            title="Log Activity" 
            class="btn btn-info"
            [ngClass]="{'ml-3' : !this.samApp.onMobile, 'mt-3 ml-2' : this.samApp.onMobile}"
            [disabled]="!canAddTask || !canEditTask || clicked" 
            (click)="clicked=true; editTaskActivity()">
            Log Activity
          </button>
          <button id="ReportActivityButton" 
            *ngIf="this.taskId != 0" 
            title="Task Activity Report" 
            class="btn btn-info"
            [ngClass]="{'ml-3' : !this.samApp.onMobile, 'mt-3 ml-2' : this.samApp.onMobile}"
            [disabled]="!canAddTask" 
            (click)="clicked=true; reportActivityPDF()">
            Task Report
          </button>
        </div>

      </mat-tab>

    </mat-tab-group>

  </div>

  <p-confirmDialog #cd
    key="deleteTask"
    [style]="{width: '33vw'}" 
    [breakpoints]="{'960px': '75vw', '640px': '95vw'}"
    [baseZIndex]="10000"
    focusTrap="true"
    closeOnEscape="true">
    <ng-template pTemplate="header">
      <h3>Delete Task</h3>
    </ng-template>
    <ng-template pTemplate="message" let-message>
      <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
        <div *ngIf="taskOrigin=='googleCalendar'">
          <span style="color:red">
            <b><i class="pi pi-exclamation-triangle mr-2"></i></b>
            The external event in the connected Google Calendar WILL be deleted too.
          </span>
          <hr>
        </div>
        Are you sure you want to delete the task?
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-warning ml-2" (click)="cd.reject()">Cancel</button>
        <button type="button" class="btn btn-danger ml-2" (click)="cd.accept()">Delete</button>
    </ng-template>
  </p-confirmDialog>

  <div *ngIf="this.samApp.onMobile">
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>

</div>