import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
  
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';

import { Auth0Service } from 'src/app/services/auth0/auth0.service';
import { SamuraiService} from 'src/services/samurai/samurai.service';
import { TQApiService } from './services/tqapi.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppRoutingGuard 
{
  appState: AppState;
  appStateSubs: any;

  constructor
  (
    private router: Router,
    private store: Store,
    private auth0: Auth0Service,
    private samApp: SamuraiService,
    private tqApi: TQApiService,
    private tqSession: TQSessionService,
  ) 
  {
    this.appStateSubs = this.store.select(appState)
    .subscribe( state => { 
      this.appState = state
      }
    )
  }

  canActivate
  (
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): Observable<boolean|UrlTree> | Promise<boolean|UrlTree> | boolean | UrlTree
  {
    // this.samApp.debug("GUARD canActivate? " + routerState.url)
    // this.tqApi.debug("GUARD canActivate? " + routerState.url)

    // Get the guards to check for
    let guards = route.data.guards as Array<string>;
    if (!guards) return true;

    // Accept if the new route is the same as the last route
    if (this.samApp.retrieveStore("TQlastURL", "session") == routerState.url)
    {
      this.samApp.debug("GUARD canActivate? " + routerState.url + " -> same route (reload)")
      this.tqApi.debug("GUARD canActivate? " + routerState.url + " -> same route (reload)")
      return true
    }


    //*** GUARD AUTH0 TO URL***//

    // Check whether the Auth0 profile is authenticated
    if (guards.indexOf('Auth0')>=0)
      {
        this.auth0.isAuthenticated()
          .then(async res => {
            let authenticated = res

            if (authenticated)
            {
              this.samApp.trace("GUARD Auth0 for: "+ routerState.url)
              this.tqApi.trace("GUARD Auth0 for: "+ routerState.url)
              return true;
            }
            else
            {
              this.samApp.trace("GUARD Auth0 NOT authenticated for " + routerState.url)
              this.tqApi.trace("GUARD Auth0 NOT authenticated for "+ routerState.url)

              await this.auth0.signin(routerState.url);
              return false;              
            }
        })
      }
    


    //*** GUARD PROFILE ***//

    // Check whether the profile needs to be verified for activation or for subscriptions
    // and return a new route if the user cannot proceed
    if (guards.indexOf('Profile') >= 0)
    {
      // this.samApp.trace("GUARD Profile: " + this.appState.TQprofileId)
      // this.tqApi.trace("GUARD Profile: " + this.appState.TQprofileId)

      // Only Check whether email is verified when appState is loaded
      // On a page reload, this gets here too fast and gets no subscriptions
      if (this.appState.Auth0Profile?.email != null)
      {
        if (this.tqSession.hasEmailVerified() != true)
        {
          this.samApp.trace("GUARD Profile: Routing to /profile by not verified email")
          this.tqApi.trace("GUARD Profile: Routing to /profile by not verified email")
          // Stay on Profile page until email is verified
          return this.router.parseUrl('/profile')
        }
      }

      // Only check subscriptions when they are already loaded
      // On a page reload, this gets here too fast and gets no subscriptions
      if (this.appState.Auth0Profile?.email != null)
      {
        if (this.appState.lastSubscription?.id == null || this.tqSession.isValidSubscription() != true)
        {
          this.samApp.trace("GUARD Profile: Routing to /profile by not valid subscription")
          this.tqApi.trace("GUARD Profile: Routing to /profile by not valid subscription")
          // Stay on Profile page until subscription is valid
          return this.router.parseUrl('/profile')
        }
      }
    }


    //*** NO MORE GUARDS ***//

    // No guards activated for this route. 
    return true
  }

    
}
