import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card'; 
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule  } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { TreeSelectModule } from 'primeng/treeselect';

import { ConfirmationService } from 'primeng/api';

import { TQDateWidgetComponent } from 'src/app/shared/widgets/tq-date-widget/tq-date-widget.component';
import { TQDurationWidgetComponent } from 'src/app/shared/widgets/tq-duration-widget/tq-duration-widget.component';
import { TQProjectMenuComponent } from 'src/app/shared/widgets/tq-project-menu/tq-project-menu.component';
import { TQRoleSelectorComponent } from 'src/app/shared/widgets/tq-role-selector/tq-role-selector.component';
import { TQShowDateTimeWidgetComponent } from 'src/app/shared/widgets/tq-show-date-time-widget/tq-show-date-time-widget.component';
import { TQTaskCardComponent } from 'src/app/shared/widgets/tq-task-card/tq-task-card.component';
import { TQTaskEventDateComponent } from 'src/app/shared/widgets/tq-task-event-date/tq-task-event-date.component';
import { TQTaskMenuComponent } from 'src/app/shared/widgets/tq-task-menu/tq-task-menu.component';
import { TQTimeWidgetComponent } from 'src/app/shared/widgets/tq-time-widget/tq-time-widget.component';
import { TQTimezonesWidgetComponent } from 'src/app/shared/widgets/tq-timezones-widget/tq-timezones-widget.component';
import { TQWeekdaysWidgetComponent } from 'src/app/shared/widgets/tq-weekdays-widget/tq-weekdays-widget.component';
import { TQWeeksWidgetComponent } from 'src/app/shared/widgets/tq-weeks-widget /tq-weeks-widget.component';

@NgModule({
  declarations: [
    TQDateWidgetComponent,
    TQDurationWidgetComponent,
    TQProjectMenuComponent,
    TQRoleSelectorComponent,
    TQShowDateTimeWidgetComponent,
    TQTaskCardComponent,
    TQTaskEventDateComponent,
    TQTaskMenuComponent,    
    TQTimeWidgetComponent,
    TQTimezonesWidgetComponent,
    TQWeekdaysWidgetComponent,
    TQWeeksWidgetComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CheckboxModule,
    CommonModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DragDropModule,
    DropdownModule,
    DialogModule,
    EditorModule,
    FontAwesomeModule,
    FormsModule,
    MatCardModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatIconModule,
    MatLuxonDateModule,
    MatMenuModule,
    MatTabsModule,
    MatTreeModule,
    SelectButtonModule,
    SplitButtonModule,
    TableModule,
    TreeTableModule,
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    CheckboxModule,
    CommonModule,
    ConfirmDialogModule,
    DragDropModule,
    DialogModule,
    EditorModule,
    FontAwesomeModule,
    FormsModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatLuxonDateModule,
    MatMenuModule,
    MatTabsModule,
    MatTreeModule,
    SelectButtonModule,
    SplitButtonModule,
    TQDateWidgetComponent,
    TQDurationWidgetComponent,
    TQProjectMenuComponent,
    TQRoleSelectorComponent,
    TQShowDateTimeWidgetComponent,
    TQTaskCardComponent,
    TQTaskEventDateComponent,
    TQTaskMenuComponent,    
    TQTimeWidgetComponent,
    TQTimezonesWidgetComponent,
    TQWeekdaysWidgetComponent,
    TQWeeksWidgetComponent,
    TableModule,
    TreeTableModule,
    TreeSelectModule,
  ],
  providers: [  
    ConfirmationService,
  ],
})
export class SharedModule {}
