<div class="samPane">
  <div class="samPaneHeader">
    <h4 class="samPaneTitle">
      <span class="mr-1" *ngIf="taskId ==0">Project Activity</span>
      <span class="mr-1" *ngIf="taskId !=0">Task Activity</span>
      <!-- <a href="https://docs.taskquark.com/activity.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a> -->
    </h4>
  </div>

  <!-- <div *ngIf="!canAddActivity"
    class="alert alert-danger" role="alert">
    You have reached the maximum number of tasks for your subscription plan.
  </div>

  <div *ngIf="!canEditactivity"
    class="alert alert-danger" role="alert">
    You do not have permissions to modify this task.
  </div> -->

  <div class="ml-2 mr-2">

    <!-- ACTIVITY HEADER  -->

    <label for="ProjectCode" 
      class="TQ-checkbox-label mt-3 ml-2 mr-2">
      Project:
    </label>
    <input id="ProjectCode"
      type="Text" 
      [disabled]="true"
      class="ml-2 mt-1 mb-1 pl-1 pr-1" 
      [ngClass]="{'col-sm-3' : this.samApp.onMobile, 'col-2' : !this.samApp.onMobile}" 
      [(ngModel)]="projectCode">
    <input id="ProjectTitle"
      type="Text" 
      [disabled]="true"
      class="ml-2 mt-1 mb-1 pl-1 pr-1" 
      [ngClass]="{'col-sm-9' : this.samApp.onMobile, 'col-8' : !this.samApp.onMobile}" 
      [(ngModel)]="projectTitle">

    <div *ngIf="taskId != 0">
      <label for="Tasktitle" 
      class="TQ-checkbox-label mt-3 ml-2 mr-2">
      Task:
    </label>
    <input id="TaskTitle"
      type="Text" 
      [disabled]="true"
      class="ml-2 mt-1 mb-1 pl-1 pr-1" 
      [ngClass]="{'col-sm-12' : this.samApp.onMobile, 'col-11' : !this.samApp.onMobile}" 
      [(ngModel)]="taskTitle">
    </div>


    <!-- ACTIVITY FORM -->

    <mat-card>
      <mat-card-content>

        <div>
          <div style="display:inline-block">
            <label for="ActivityStartDatePicker" 
              class="TQ-checkbox-label mt-3 ml-2 mr-2">
              Date:
            </label>
            <tq-date-widget #ActivityStartDatePicker
              [maxDate]="activityEndDate"
              [(date)]="activityStartDate"
            ></tq-date-widget>  
            <button 
              class="badge TQ-clear-button ml-1" 
              (click)="clearActivityStartDate()">
              X
            </button>
          </div>
          <div *ngIf="activityStartDate" 
            style="display:inline-block">
            <tq-time-widget class="ml-3"  
              [(time)]="activityStartTime"
              [timeFormat]=this.appState.prefLocTimeFormat
            ></tq-time-widget>
            <button 
              class="badge TQ-clear-button ml-1" 
              (click)="clearActivityStartTime()">
              X
            </button>
          </div>
      
          <br>
      
          <label 
            class="TQ-checkbox-label mt-3 ml-2 mr-1">
            Duration:
          </label>
          <tq-duration-widget 
            [(duration)]="activityDuration"
          ></tq-duration-widget>
          <button class="badge TQ-clear-button ml-1" 
            (click)="clearActivityDuration()">
            X
          </button>
      
          <label 
            class="TQ-checkbox-label mt-3 ml-3 mr-1">
            Allocation:
          </label>
          <select 
            type="checkbox"
            [(ngModel)]="activitAllocation">
            <option value="charged">charged</option>
            <option value="absorbed">absorbed</option>
            <option value="internal">internal</option>
          </select>

          <label for="activityDescription" 
            class="TQ-checkbox-label mt-3 ml-3 mr-1">
            Description:
          </label>
          <input id="activityDescription" 
            type="text" 
            class="ml-1 mt-1 mb-1 pl-1 pr-1" 
            [ngClass]="{'col-sm-12' : this.samApp.onMobile, 'col-7' : !this.samApp.onMobile}" 
            [(ngModel)]="activityDescription"
          >

          <hr>

          <button id="ActivitySaveButton"
            title="Add Activity" 
            class="btn btn-success ml-2" 
            [disabled]="!canAddActivity || this.activityStartDate == null || (this.activityDuration == null && !this.activityDescription)" 
            (click)="editing=false; addActivity()"
          >
            {{ editing ? "Save" : "Add" }}
          </button>
          <button id="ActivityCancelButton"
            title="Cancel Activity" 
            class="btn btn-warning ml-2" 
            (click)="editing=false; cancelActivity()"
          >
            Cancel
          </button>
          <button id="ActivityDuplicateButton"
            *ngIf="editing" 
            title="Duplicate Activity" 
            class="btn btn-success ml-3" 
            [disabled]="!canAddActivity || this.activityStartDate == null || (this.activityDuration == null && !this.activityDescription)"  
            (click)="editing=false; duplicateActivity()"
          >
            Duplicate
          </button>
          <button id="ActivityDeleteButton"
            *ngIf="editing" 
            title="Delete Activity" 
            class="btn btn-danger ml-5" 
            style="float:right"
            [disabled]="!canEditActivity" 
            (click)="editing=false; confirmDeleteActivity()"
          >
            Delete
          </button>
        </div>            

        <hr>
      
        <!-- ACTIVITY TABLE -->

          <div class="TQ-center-container mt-3">
            <label for="ActivityReportStartDate" 
              class="TQ-checkbox-label ml-2 mr-1">
              From date:
            </label>
            <tq-date-widget 
              [(date)]="activityReportStartDate"
              [maxDate]="activityReportEndDate"
              (dateChange)="updateActivityReport()"
            ></tq-date-widget>
            <button 
              class="badge TQ-clear-button ml-1" 
              (click)="clearActivityReportStartDate()">
              X
            </button>
  
            <label for="ActivityReportEndDate" 
              class="TQ-checkbox-label ml-3 mr-1">
              to:
            </label>
            <tq-date-widget 
              [(date)]="activityReportEndDate"
              [minDate]="activityReportStartDate"
              (dateChange)="updateActivityReport()"
            ></tq-date-widget>
            <button 
              class="badge TQ-clear-button ml-1" 
              (click)="clearActivityReportEndDate()">
              X
            </button>

            <label 
              class="TQ-checkbox-label ml-5">
              Include:
            </label>
            <p-checkbox 
              class="ml-0"
              [binary]="true"
              [(ngModel)]="activityReportIncludeNonBillable"
              (ngModelChange)="updateActivityReport()"
            />
            <label 
              class="TQ-checkbox-label ml-1">
              absorbed
            </label>
            <p-checkbox 
              class="ml-1"
              [binary]="true"
              [(ngModel)]="activityReportIncludeInternal"
              (ngModelChange)="updateActivityReport()"
            />
            <label 
              class="TQ-checkbox-label ml-1">
              internal
            </label>

          </div>
  
          <mat-card class="m-1">
            <mat-card-content>
  
            <div class="row m-1">
              <div class="ml-1"><b>Number of entries: </b>{{activities_number}}</div>
              <div class="ml-4"><b>Total duration: </b>{{tqDT.minutesToDuration(activities_time)}}</div>
            </div>

            <p-table *ngIf="activities && activities.length"
              [value]="activities" 
              sortField="startDate" [sortOrder]="-1"
              rowGroupMode="rowspan" groupRowsBy="startDate"
              styleClass="p-datatable-gridlines"
              [paginator]="true"
              [rows]="15"
              [rowsPerPageOptions]="[15, 30]"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th class="p-2" style="width: 110px; text-align:center;"
                    pSortableColumn="startDate">
                    Date
                    <p-sortIcon field="startDate"></p-sortIcon>
                  </th>
                  <th class="p-2" style="width: 85px"></th>
                  <th class="p-2" style="width: 75px">Duration</th>
                  <th class="p-2" style="width: 20px"></th>
                  <th class="p-2" >Description</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-act let-rowgroup="rowgroup" let-rowspan="rowspan">
                <tr 
                  [class.table-success]="(act.id==activityId)"
                  (click)="editActivity(act.id)"          
                >
                  <td class="p-1" style="text-align:center; vertical-align:top;"
                    *ngIf="rowgroup" [attr.rowspan]="rowspan">
                    {{ tqDT.dateToTZ(act.startDate, act.startTime) }} 
                  </td>
                  <td class="p-1" style="text-align:center">
                    {{ tqDT.formatTimeFromISO(act.startDate, tqDT.timeToTZ(act.startDate, act.startTime)) }}
                  </td>
                  <td class="p-1" style="text-align:right">
                    <span *ngIf="act.value && act.value !== 0">{{ tqDT.minutesToDuration(act.value) }}</span>
                  </td>
                  <td class="p-1" style="text-align:center">
                    <span *ngIf="act.allocation=='charged'"><fa-icon [icon]="faArrowRight" title="charged"  style="color:gray;"></fa-icon></span>
                    <span *ngIf="act.allocation=='absorbed'"><fa-icon [icon]="faArrowLeft" title="absorbed" style="color:lightgray;"></fa-icon></span>
                    <span *ngIf="act.allocation=='internal'"><fa-icon [icon]="faArrowDown" title="internal" style="color:lightgray;"></fa-icon></span>
                  </td>
                  <td class="p-1">
                    {{ act.description }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
   
          </mat-card-content>
        </mat-card>

        <div class="mt-2">
          <button id="ReportProjectActivityButton" 
            title="Project Activity Report" 
            class="btn btn-info"
            [ngClass]="{'ml-3' : !this.samApp.onMobile, 'mt-3 ml-2' : this.samApp.onMobile}"
            [disabled]="!canAddActivity" 
            (click)="reportProjectActivityPDF()">
            Project Report
          </button>
          <button id="ReportTaskActivityButton" 
            *ngIf="this.taskId != 0" 
            title="Task Activity Report" 
            class="btn btn-info"
            [ngClass]="{'ml-3' : !this.samApp.onMobile, 'mt-3 ml-2' : this.samApp.onMobile}"
            [disabled]="!canAddActivity" 
            (click)="reportTaskActivityPDF()">
            Task Report
          </button>
        </div>

      </mat-card-content>
    </mat-card>

  </div>


  <p-confirmDialog #cd
    key="deleteActivity"
    [style]="{width: '33vw'}" 
    [breakpoints]="{'960px': '75vw', '640px': '95vw'}"
    [baseZIndex]="10000"
    focusTrap="true"
    closeOnEscape="true">
    <ng-template pTemplate="header">
      <h3>Delete Activity</h3>
    </ng-template>
    <ng-template pTemplate="message" let-message>
      <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          Are you sure you want to delete this activity?
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-warning ml-2" (click)="cd.reject()">Cancel</button>
        <button type="button" class="btn btn-danger ml-2" (click)="cd.accept()">Delete</button>
    </ng-template>
  </p-confirmDialog>

  <div *ngIf="this.samApp.onMobile">
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>

</div>
