import { Injectable } from '@angular/core';

import { Store } from "@ngrx/store";
import { AppState, CONSTANTS, TQSubscription } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { ProjectsState } from 'src/app/projects/store/projects.state';
import { projectsState } from 'src/app/projects/store/projects.selectors';
import { TasksState } from 'src/app/tasks/store/tasks.state';
import { tasksState } from 'src/app/tasks/store/tasks.selectors';
import { NotesState } from 'src/app/notes/store/notes.state';
import { notesState } from 'src/app/notes/store/notes.selectors';

import { TQApiService } from './tqapi.service';
import { SamuraiService } from 'src/services/samurai/samurai.service';

import { DateTime, Duration } from 'luxon'

@Injectable({
  providedIn: 'root'
})
export class TQSessionService
{
  appState: AppState;
  appStateSubs: any;

  projectsState: ProjectsState;
  projectsStateSubs: any;

  tasksState: TasksState;
  tasksStateSubs: any;

  notesState: NotesState;
  notesStateSubs: any;

  //--- Profile Configuration ---//
  private cfgRoleSelectedId = null;

  //--- Profile Products ---//
  private TQproductPromotions: String = '';

  //--- Profile Subscriptions ---//
  private TQSubscriptions: any = null;

  private status: string = 'init'

  constructor
  (
    private store: Store,
    private tqApi: TQApiService,
    private samApp: SamuraiService,
  )
  {
    this.samApp.debug("TQSESSION loading...")
    this.status = 'loading' 

    this.appStateSubs = this.store.select(appState)
    .subscribe( state => {
      this.appState = state 

      this.cfgRoleSelectedId = this.appState.cfgRoleSelectedId
    })

    this.projectsStateSubs = this.store.select(projectsState)
    .subscribe( state => {
      this.projectsState = state 
    })

    this.tasksStateSubs = this.store.select(tasksState)
    .subscribe( state => {
      this.tasksState = state 
    })

    this.notesStateSubs = this.store.select(notesState)
    .subscribe( state => {
      this.notesState = state 
    })

    this.cfgRoleSelectedId = this.appState.cfgRoleSelectedId
  }

  getStatus() 
  {
    return this.status
  }


  /*** PROFILE MANAGEMENT ***/
  
  async postTQProfile()
  {
    try
    {
      // Post Auth0 profile to TQApiController
      let res = await this.tqApi.postProfile(this.appState.Auth0Profile)

      this.store.dispatch(APP_ACTIONS.setTQprofileId({id: res['tqapi_profile_id']}))

      this.samApp.trace("TQSESSION POST Auth0Profile OK.")
    }
    catch (apiError)
    {
      this.store.dispatch(APP_ACTIONS.setTQprofileId({id: null}))

      this.samApp.trace("TQSESSION POST Auth0Profile KO.")
    }
  }

  async getTQProfile()
  {
    try
    {
      let res = await this.tqApi.getProfile()

      // Set default values
      if (res['prefLocTimeZone'] == null)   res['prefLocTimeZone']="UTC"
      if (res['prefLocDateFormat'] == null) res['prefLocDateFormat']="YYYY-MM-DD"
      if (res['prefLocTimeFormat'] == null) res['prefLocTimeFormat']="24h"
      
      // Save TQsession in localStore for page reloads
      this.samApp.saveStore("TQsession", 
        {
        "id"                         : res['id'],
        "email"                      : `${res['email']}`,
        "email_verified"             : this.appState.Auth0Profile.email_verified, 
        "account"                    : res['account'],
        "default_project_id"         : res['default_project_id'], 
        "prefLoginStartPad"          : `${res['prefLoginStartPad']}`,
        "prefLogoutAfterInactiveFor" : `${res['prefLogoutAfterInactiveFor']}`,
        "prefLocTimeZone"            : `${res['prefLocTimeZone']}`,
        "prefLocWeekStart"           : `${res['prefLocWeekStart']}`,
        "prefLocWeekendDays"         : `${res['prefLocWeekendDays']}`,
        "prefLocDateFormat"          : `${res['prefLocDateFormat']}`,
        "prefLocTimeFormat"          : `${res['prefLocTimeFormat']}`,
        "prefWorkpadNumCols"         : res['prefWorkpadNumCols'],
        "prefWorkpadCol1"            : res['prefWorkpadCol1'],
        "prefWorkpadCol2"            : res['prefWorkpadCol2'],
        "prefWorkpadCol3"            : res['prefWorkpadCol3'],
        "prefWorkpadCol4"            : res['prefWorkpadCol4'],
        "prefWorkpadCol5"            : res['prefWorkpadCol5'],
        "prefWorkpadCol1Mobile"      : res['prefWorkpadCol1Mobile'],
        "prefWorkpadCol2Mobile"      : res['prefWorkpadCol2Mobile'],
        "prefWorkpadCol3Mobile"      : res['prefWorkpadCol3Mobile'],
        "prefWorkpadCol4Mobile"      : res['prefWorkpadCol4Mobile'],
        "prefWorkpadCol5Mobile"      : res['prefWorkpadCol5Mobile'],
        "prefTaskRadarEnabled"       : res['prefTaskRadarEnabled'],
        "prefTaskRadarFreq"          : `${res['prefTaskRadarFreq']}`,
        "prefTaskRadarRange"         : `${res['prefTaskRadarRange']}`,
        "cfgRoleSelectedId"          : res['cfg_role_selected_id'],
        }
      );

      this.store.dispatch(APP_ACTIONS.setTQprofileId({id: res['id']}))
      this.store.dispatch(APP_ACTIONS.setTQprofileEmail({email: res['email']}))
      this.store.dispatch(APP_ACTIONS.setTQroleSelectedId({id: res['cfg_role_selected_id']}))
      this.store.dispatch(APP_ACTIONS.setTQprofilePrefs({profile: res}))

      // Update TQsession in App state
      this.samApp.trace("TQSESSION getProfile -> TQSESSION loaded OK: " + res.id)
      this.status = 'loaded' 
    }
    catch (apiError)
    {
      this.status = 'error' 
      this.samApp.trace("TQSESSION: Error getting profile")

      this.samApp.saveStore("TQsession", 
        {
        "id"                           : null,
        "email"                        : null,
        "email_verified"               : false,
        "account"                      : null,
        "default_project_id"           : null,
        "prefPrefLoginStartPad"        : "workpad",
        "prefLogoutAfterInactiveFor"   : null,
        "prefLocTimeZone"              : null,
        "prefLocWeekStart"             : null,
        "prefLocWeekendDays"           : null,
        "prefLocDateFormat"            : null,
        "prefLocTimeFormat"            : null,
        "prefWorkpadNumCols"           : null,
        "prefWorkpadCol1"              : null,
        "prefWorkpadCol2"              : null,
        "prefWorkpadCol3"              : null,
        "prefWorkpadCol4"              : null,
        "prefWorkpadCol5"              : null,
        "prefWorkpadCol1Mobile"        : null,
        "prefWorkpadCol2Mobile"        : null,
        "prefWorkpadCol3Mobile"        : null,
        "prefWorkpadCol4Mobile"        : null,
        "prefWorkpadCol5Mobile"        : null,
        "prefTaskRadarEnabled"         : false,
        "prefTaskRadarFreq"            : null,
        "prefTaskRadarRange"           : null,
        "cfgRoleSelectedId"            : null,
        }
      );

      // Clean TQsession
      this.store.dispatch(APP_ACTIONS.setTQprofileId({id: null}))
      this.store.dispatch(APP_ACTIONS.setTQprofileEmail({email: null}))
      this.store.dispatch(APP_ACTIONS.setTQroleSelectedId({id: null}))
      this.store.dispatch(APP_ACTIONS.setTQprofilePrefs({profile: null}))

      // Update TQsession in App state
      this.samApp.trace("TQSESSION loaded KO.")
    }
  }

  /*** TQ SESSION ***/

  hasEmailVerified(): boolean
  {
    if (this.appState?.Auth0Profile?.email_verified == null) return false;

    return this.appState.Auth0Profile.email_verified;
  }


  /*** TQ PRODUCTS ***/

  getTQproductPromotions()
  {
    return this.TQproductPromotions
  }
  setTQproductPromotions(promos: String)
  {
    this.TQproductPromotions = promos
  }

  async getPromotionsByProfile()
  {
    try
    {
      let res = await this.tqApi.getProfileProductPromotions()
      this.setTQproductPromotions(res);

      // this.store.dispatch(APP_ACTIONS.setTQproductPromotions({promos: res["promotions"]}))
    }
    catch (apiError)
    {
      this.setTQproductPromotions("");

      // this.store.dispatch(APP_ACTIONS.setTQproductPromotions({promos: ""}))
    }
  }

  /*** TQ ROLES ***/

  async getRolesByProfile()
  {
    try
    {
      let res = await this.tqApi.getRolesByProfile()

      this.store.dispatch(APP_ACTIONS.setTQroles({roles: res}))
    }
    catch (apiError)
    {
      // Set default values
      this.store.dispatch(APP_ACTIONS.setTQroles({roles: []}))
      this.store.dispatch(APP_ACTIONS.setTQroleSelectedId({id: null}))
    }
  }

  getProfileRoleCode()
  {
    if (this.appState.TQroles.length == 0) return "";

    let res = null;
    if (this.cfgRoleSelectedId == null) 
    {
      res = "";
    }
    else 
    {
      res = this.appState.TQroles.find(role => role.id == this.cfgRoleSelectedId).code
    }
    return res
  }

  /**
   * Returns the role object for the given role id
   * @param id Role id number
   * @returns Role object with id, code and description
   */
  getTQRole(id: number)
  {
    if (id == null || this.appState.TQroles.length == 0) return {
      id: null,
      code: CONSTANTS.ROLE.ALL_code,
      description: CONSTANTS.ROLE.ALL_description,
    }
    
    let res = this.appState.TQroles.find(role => role.id == id)
    return res
  }


  /*** TQ SUBSCRIPTIONS ***/

  getTQsubscriptions()
  {
    return this.TQSubscriptions
  }
  setTQsubscriptions(subscriptions: any)
  {
    this.TQSubscriptions = subscriptions
  }

  async getSubscriptionsByProfile()
  {
    try
    {
      let res = await this.tqApi.getSubscriptionsByProfile()
      this.setTQsubscriptions(res);
      if (res.length > 0)
      {
        // Find last subscription
        this.store.dispatch(APP_ACTIONS.setLastSubscription({subscription: res[0]}))

        // Find first trial subscription
        let earliestTrial:TQSubscription = null;
        for (let sub of res)
        {
          if (sub.plan.includes('trial'))
          {
            if (earliestTrial == null || sub.startDate < earliestTrial.startDate)
            {
              earliestTrial = sub;
            }
          }
        }
        this.store.dispatch(APP_ACTIONS.setTrialSubscription({subscription: earliestTrial}));
      }
    }
    catch (apiError)
    {
      this.setTQsubscriptions([]);
      this.store.dispatch(APP_ACTIONS.setLastSubscription({subscription: 
        {
          id: null, 
          plan: null, 
          startDate: null,
          lastPayment: null, 
          dateCanceled: null
        }}))
      this.store.dispatch(APP_ACTIONS.setTrialSubscription({subscription: 
        {
          id: null, 
          plan: null, 
          startDate: null,
          lastPayment: null, 
          dateCanceled: null
        }}))
      }
  }

  // TODO Move to TQApiController
  isValidSubscription()
  {
    if (this.appState == undefined) return false; 
    if (this.appState?.lastSubscription?.id == null) return false;

    // Reject paid subscriptions not paid
    if (this.appState.lastSubscription.lastPayment == null && !['free', 'trial'].some(plan => this.appState.lastSubscription.plan.includes(plan)))
    {
      return false;
    }   

    // Accept non-cancelled free subscriptions
    if (['free'].some(plan => this.appState.lastSubscription.plan.includes(plan)))
    {
      // Reject canceled subscriptions 
      if (this.appState.lastSubscription.dateCanceled != null)
        {
          return false;
        }

      return true;
    }

    // Check other non-free subscriptions
    // TODO get end date from external subscription
    var periodDays:number = 0;
    switch (this.appState.lastSubscription.plan)
    {
      case '2401-guest-1m'           : periodDays =  31 ; break; 
      case '2401-basic-3m'           : periodDays =  90 ; break; 
      case '2401-personal-3m'        : periodDays =  90 ; break;
      case '2401-personal-1y'        : periodDays = 365 ; break;
      case '2501-professional-trial' : periodDays =  30 ; break;
      case '2401-professional-1m'    : periodDays =  31 ; break;
      case '2401-professional-1y'    : periodDays = 365 ; break;
    }

    let startDate: DateTime = null;
    if (['trial'].some(plan => this.appState.lastSubscription.plan.includes(plan)))
    {
      startDate = DateTime.fromISO(this.appState.trialSubscription.startDate)
    }
    else
    {
      startDate = DateTime.fromISO(this.appState.lastSubscription.startDate)
    }

    let lastPayment: DateTime = null;
    if (this.appState.lastSubscription.lastPayment == null)
    {
      // Simulate a last payment date
      if (['trial'].some(plan => this.appState.lastSubscription.plan.includes(plan)))
      {
        lastPayment = DateTime.fromISO(this.appState.trialSubscription.startDate)
      }
      else
      {
        lastPayment = startDate.minus({days: periodDays})
      }
    }
    else
    {
      lastPayment = DateTime.fromISO(this.appState.lastSubscription.lastPayment)
    }

    let now = DateTime.now().toUTC() // .plus({year: 0}).plus({month: 2});   console.log("NOW: ", now.toISO())

    // Add 0 days to cancel subscription immediately
    var graceDays = (periodDays + 1) - now.diff(lastPayment, 'days').as('days')

    // Determine is subscription is still 
    return (graceDays > 0 && startDate <= now )
  }

  // TODO Move to TQApiController
  isCanceledSubscription()
  {
    if (this.appState == undefined) return false; 
    if (this.appState?.lastSubscription?.id == null) return true;

    if (this.appState.lastSubscription.dateCanceled != null)
    {
      return true;
    }

    return false;
  }

  isUpgradableSubscription()
  {
    return true; 

    // Allow adding a subscription when none available
    if (this.appState?.lastSubscription?.id == null) return true;

    // Upgrade canceled subscriptions
    if (this.appState.lastSubscription.dateCanceled != null)
    {
      return true;
    }

    // Upgrade free subscriptions
    if (this.appState.lastSubscription.plan.includes("free"))
    {
      return true;
    }

    // Upgrade invalid subscriptions
    if (!this.isValidSubscription())
    {
      return true;
    }

    return false;
  }

  isCancelableSubscription()
  {
    if (this.appState?.lastSubscription?.id == null) return false;

    if (this.appState.lastSubscription.id == null) return false;

    // Do not cancel canceled subscriptions
    if (this.appState.lastSubscription.dateCanceled != null)
    {
      return false;
    }

    // Allow canceling all subscriptions
    return true;
  }

  isUnpaidSubscription()
  {
    if (this.appState?.lastSubscription?.id != null)
    {
      // Trial and Free subscriptions are never unpaid
      if (['trial', 'free'].some(plan => this.appState.lastSubscription.plan.includes(plan)))
      {
        return false;
      }

      // Paid subscriptions, check last payment
      if (this.appState.lastSubscription.lastPayment) 
      {
        return false;
      }
    } 

    return false
  }
    
  /*--- SUBSCRIPTION LIMITS ---*/

  // TODO Get limits from TQ BDparams at tqSession 

  canAddNote() 
  { 
    // if (this.notesState.status != 'loaded') return false; // State not ready
    // if (this.appState.lastSubscription.id == null) return false;   // No subscription yet

    switch (this.appState.lastSubscription.plan)
    {
      case "2401-guest-1m": 
        return false;
      case "free":
      case "2020-01-personal-free":
      case "2401-personal-free":
      case "2401-professional-free":
      case '2501-professional-trial':
        return true;
      case "2401-basic-3m": 
        return (this.notesState.TQnotes.length < 10) ? true : false;
      case "2401-personal-3m": 
      case "2401-professional-1m": 
      case "2401-professional-1y": 
        return true;
      default: 
        return true; // Allow until limits are set
    }
  }

  canAddProject() 
  { 
    // if (this.projectsState.status == 'empty') return false; // State not ready
    // if (this.appState.lastSubscription.id == null) return true;   // No subscription yet

    switch (this.appState.lastSubscription.plan)
    {
      case "2401-guest-1m": 
        return false;
      case "free":
      case "2020-01-personal-free":
      case "2401-personal-free":
      case "2401-professional-free": 
      case '2501-professional-trial':
        return true;
      case "2401-basic-3m": 
        return (this.projectsState.TQprojects.length < 5) ? true : false;
      case "2401-personal-3m": 
      case "2401-professional-1m": 
      case "2401-professional-1y": 
        return true;
      default: 
        return true; // Allow until limits are set
    }
  }

  canAddTask() 
  { 
    // if (this.tasksState.status != 'loaded') return false; // State not ready
    // if (this.appState.lastSubscription.id == null) return true;   // No subscription yet

    switch (this.appState.lastSubscription.plan)
    {
      case "2401-guest-1m": 
        return false;
      case "free":
      case "2020-01-personal-free":
      case "2401-personal-free":
      case "2401-professional-free": 
      case '2501-professional-trial':
        return true;
      case "2401-basic-3m": 
        return (this.tasksState.TQtasks.length < 50) ? true : false;
      case "2401-personal-3m": 
      case "2401-professional-1m": 
      case "2401-professional-1y": 
        return true;
      default: 
        return true; // Allow until limits are set
   }
  }

}