import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tq-weeks-widget',
  templateUrl: './tq-weeks-widget.component.html',
  styleUrl: './tq-weeks-widget.component.scss'
})
export class TQWeeksWidgetComponent implements OnInit, OnChanges
{
  @Input({required: true}) weeks: string; 
  @Output() weeksChange = new EventEmitter<string>();

  monthweeks = [];

  wom : any[] = [
    { name: "1st.", value: "O1" },
    { name: "2nd.", value: "O2" },
    { name: "3rd.", value: "O3" },
    { name: "4th.", value: "O4" },
    { name: "last", value: "O5" },
  ];  

  constructor() 
  {}

  ngOnInit(): void 
  {
    if (!this.weeks || this.weeks.length == 0)
    {
      this.monthweeks = [];
    }
    else
    {
      this.monthweeks = this.weeks.split(' ');
    }
  }

  // Parent component changes 
  ngOnChanges()
  {
    if (!this.weeks || this.weeks.length == 0)
    {
      this.monthweeks = [];
    }
    else
    {
      this.monthweeks = this.weeks.split(' ');
    }
  } 
  
  // Local changes 
  changeWeeks() 
  {
    this.weeksChange.emit(this.monthweeks.join(' '));
  }
  

}
